import { Actions_, Builder_, Grey_, IconSvg, Input_, Main_, Main_Plain, Page, Slider_ } from 'monica-alexandria'
import React, { useState } from 'react'
import { DigTraVou, DigTraVouBtn, DigTraVouCard, DigTraVouHeader, DigTraVouHero, DigTraVouMain, DigTraVouPrice, DigTraVouSection, DigTraVouSectionRev, DigTraVouTable, DigTraVouVat } from './DIGITAL_TRANSFORMATION.css'

export default function DIGITAL_TRANSFORMATION() {

    const [intrestedSlider, toggleIntrestedSlider] = useState(false);

  return (
    <Page>
        {intrestedSlider &&
            <Slider_
                onClick = {() => toggleIntrestedSlider(false)}
                onClose = {() => toggleIntrestedSlider(false)}
                text = 'Ενδιαφέρομαι'
                title = 'Φόρμα ενδιαφέροντος'
            >
                <Builder_ 
                    title = 'Συμπληρώστε το τηλέφωνό σας'
                    noPads
                >
                    <Input_ 
                        placeholder = 'Αριθμός τηλεφώνου'
                    />
                </Builder_>
                <Builder_ 
                    title = 'Συμπληρώστε το Όνομά σας'
                    noPads
                >
                    <Input_ 
                        placeholder = 'Όνομα'
                    />
                </Builder_>
                <Builder_ 
                    title = 'Συμπληρώστε το Επίθετό σας'
                    noPads
                >
                    <Input_ 
                        placeholder = 'Επίθετο'
                    />
                </Builder_>
                <Actions_>
                    <Grey_ 
                        text = 'Ακύρωση'
                        iconLeft = 'Xicon'
                        onClick = {() => toggleIntrestedSlider(false)}
                    />
                    <Main_ 
                        text = 'Ενδιαφέρομαι'
                        iconRight = 'Check'
                        onClick = {() => toggleIntrestedSlider(false)}
                    />
                </Actions_>
            </Slider_>
        }
        <DigTraVouHeader>
                <img src='https://genius1071.friktoriaservers.net/mongol/app/illus/landing/becomeBg.svg' />
                <DigTraVouHero>
                    <h1>Ψηφιακός μετασχηματισμός</h1>
                </DigTraVouHero>
            </DigTraVouHeader>
        <DigTraVou>
            <DigTraVouMain>
                <DigTraVouSectionRev >
                    <aside>
                        <img src="https://genius1071.friktoriaservers.net/doitforme/morning/app/fahed/digTraVou/Webapps.svg" />
                    </aside>
                    <main>
                        <h2>do<span>ESSENTIAL</span>forme</h2>
                        <h1>WEBDEV</h1>
                        <p>
                            Αναλαμβάνουμε την κατασκευή της επαγγελματικής παρουσιαστικής σας σελίδας χρησιμοποιώντας το εργαλείο του wordpress. Το θέμα το οποίο χρησιμοποιούμε είναι το ίδιο για όλους του πελάτες του πακέτου μας και μπορείτε να το δείτε σε αυτό τον σύνδεσμο
                            <Main_Plain 
                                noPadding
                                text = 'Blocksy wordpress theme'
                                onClick = {() => window.location.assign('https://creativethemes.com/blocksy/')}
                            />
                            Παραδίδεται μια τετρασέλιδη ιστοσελίδα σε όλους του πελάτες μας. Οι σελίδες παραθέτονται στη συνέχεια και το περιεχόμενο τους συμπληρώνεται μέσα από το διαχειριστικό περιβάλλον του wordpress-admin εργαλείου το οποίο παραδίδεται με το πέρας της εργασίας στον πελάτη. Ο πελάτης μπορεί να παραμετροποιήσει την ιστοσελίδα του με τη χρήση του διαχειριστικού εργαλείου.                        </p>
                        <DigTraVouCard>
                            <DigTraVouPrice>
                                <h2>Εφάπαξ</h2>
                                <h4>€ 2000<span className='Per'>/τεμάχιο</span></h4>
                            </DigTraVouPrice>
                            <ul>
                                <li>
                                    <IconSvg Icon = 'Check'/>
                                    <h5>Αρχική σελίδα</h5>
                                </li>
                                <li>
                                    <IconSvg Icon = 'Check'/>
                                    <h5>Σελίδα επικοινωνίας</h5>
                                </li>
                                <li>
                                    <IconSvg Icon = 'Check'/>
                                    <h5>Σελίδα περιγραφής επιχείρησης </h5>
                                </li>
                                <li>
                                    <IconSvg Icon = 'Check'/>
                                    <h5>Σελίδα καταστήματος</h5>
                                </li>
                                <li>
                                    <IconSvg Icon = 'Check'/>
                                    <h5>Εργαλείο διαχείρισης περιεχομένου</h5>
                                </li>
                            </ul>
                            <DigTraVouTable>
                                <p></p>
                                <p>% (Ποσοστό)</p>
                                <p>€ (Τιμή)</p>
                                <h4>Συνολικό κόστος</h4>
                                <h4>100%</h4>
                                <h4>€ 2000,00</h4>
                                <p>Κρατική ενίσχυση</p>
                                <p>90%</p>
                                <p>€ 1800,00</p>
                                <p>Ιδία συμμετοχή</p>
                                <p>10%</p>
                                <p>€ 200,00</p>
                            </DigTraVouTable>
                            <DigTraVouVat>
                                <h6>Οι τιμές δεν συμπεριλαμβάνουν ΦΠΑ</h6>
                            </DigTraVouVat>
                            <DigTraVouBtn onClick = {() => toggleIntrestedSlider(true)}>
                                <h4>Ενδιαφέρομαι</h4>
                            </DigTraVouBtn>
                        </DigTraVouCard>
                    </main>
                </DigTraVouSectionRev>
                <DigTraVouSection>
                    <main>
                        <h2>do<span>ESSENTIAL</span>forme</h2>
                        <h1>INTROPLAN</h1>
                        <p>Εργαλείο διαχειρίσης επαγγελματικής σελίδας με το οποίο προσελκύεις αυτοματοποιημένα υποψήφιους πελάτες ή  αιτήσεις εργασίας σε συγκεκριμένες περιοχές και κλάδο υπηρεσιών της επιλογής σου,  μέσα από το δίκτυο της πλατφόρμας της Doitforme. Πιο απλά, πρόκειται για μια πλατφόρμα στην οποία μπορεί ο χρήστης να δημιουργήσει προφίλ συμπληρώνοντας τα στοιχεία του. Αυτό το προφίλ στη συνέχεια μπορεί να διαφημιστεί χρησιμοποιώντας διάφορα εργαλεία της πλατφόρμας και έτσι να αποκτήσει περισσότερους υποψήφιους πελάτες. Είναι ένα πλήρως αυτοματοποιημένο εργαλείο το οποίο είναι ενιαίο για όλους τους χρήστες.</p>
                        <DigTraVouCard>
                            <DigTraVouPrice>
                                <h2>Συνδρομή</h2>
                                <h4>€ 1000<span className='Per'>/έτος</span></h4>
                            </DigTraVouPrice>
                            <ul>
                                <li>
                                    <IconSvg Icon = 'Check'/>
                                    <h5>Εργαλείο κατασκευής επαγγελματικής σελίδας</h5>
                                </li>
                                <li>
                                    <IconSvg Icon = 'Check'/>
                                    <h5>Ανάδειξη στις αναζητήσεις εντός πλατφόρμας</h5>
                                </li>
                                <li>
                                    <IconSvg Icon = 'Check'/>
                                    <h5>Ανάδειξη στα οργανικά αποτελέσματα των σελίδων μας μέσα από γνωστές μηχανές αναζήτησης</h5>
                                </li>
                                <li>
                                    <IconSvg Icon = 'Check'/>
                                    <h5>Έως τρεις περιοχές δραστηριοποίησης ανά συνδρομή</h5>
                                </li>
                                <li>
                                    <IconSvg Icon = 'Check'/>
                                    <h5>Έως ένα κλάδο υπηρεσιών δραστηριοποίησης ανά συνδρομή</h5>
                                </li>
                                <li>
                                    <IconSvg Icon = 'Check'/>
                                    <h5>Έως 123 οργανικές αιτήσεις εργασίας από υποψήφιους πελάτες πάνω στον κλάδο υπηρεσιών μέχρι το πέρας της συνδρομής</h5>
                                </li>
                            </ul>
                            <DigTraVouTable>
                                <p></p>
                                <p>% (Ποσοστό)</p>
                                <p>€ (Τιμή)</p>
                                <h4>Συνολικό κόστος</h4>
                                <h4>100%</h4>
                                <h4>€ 1000,00</h4>
                                <p>Κρατική ενίσχυση</p>
                                <p>90%</p>
                                <p>€ 900,00</p>
                                <p>Ιδία συμμετοχή</p>
                                <p>10%</p>
                                <p>€ 100,00</p>
                            </DigTraVouTable>
                            <DigTraVouVat>
                                <h6>Οι τιμές δεν συμπεριλαμβάνουν ΦΠΑ</h6>
                            </DigTraVouVat>
                            <DigTraVouBtn onClick = {() => toggleIntrestedSlider(true)}>
                                <h4>Ενδιαφέρομαι</h4>
                            </DigTraVouBtn>
                        </DigTraVouCard>
                    </main>
                    <aside> 
                        <img src="https://genius1071.friktoriaservers.net/doitforme/morning/app/fahed/digTraVou/Eshop.svg" />
                    </aside>
                </DigTraVouSection>
                <DigTraVouSectionRev >
                    <aside>
                        <img src="https://genius1071.friktoriaservers.net/doitforme/morning/app/fahed/digTraVou/Marketing.svg" />
                    </aside>
                    <main>
                        <h2>do<span>ESSENTIAL</span>forme</h2>
                        <h1>PROPLAN</h1>
                        <p>Εργαλείο διαχειρίσης επαγγελματικής σελίδας με το οποίο προσελκύεις αυτοματοποιημένα υποψήφιους πελάτες ή  αιτήσεις εργασίας σε συγκεκριμένες περιοχές και κλάδο υπηρεσιών της επιλογής σου,  μέσα από το δίκτυο της πλατφόρμας της Doitforme. Πιο απλά, πρόκειται για μια πλατφόρμα στην οποία μπορεί ο χρήστης να δημιουργήσει προφίλ συμπληρώνοντας τα στοιχεία του. Αυτό το προφίλ στη συνέχεια μπορεί να διαφημιστεί χρησιμοποιώντας διάφορα εργαλεία της πλατφόρμας και έτσι να αποκτήσει περισσότερους υποψήφιους πελάτες. Είναι ένα πλήρως αυτοματοποιημένο εργαλείο το οποίο είναι ενιαίο για όλους τους χρήστες.</p>
                        <DigTraVouCard>
                            <DigTraVouPrice>
                                <h2>Συνδρομή</h2>
                                <h4>€ 4000<span className='Per'>/έτος</span></h4>
                            </DigTraVouPrice>
                            <ul>
                                <li>
                                    <IconSvg Icon = 'Check'/>
                                    <h5>Εργαλείο κατασκευής επαγγελματικής σελίδας</h5>
                                </li>
                                <li>
                                    <IconSvg Icon = 'Check'/>
                                    <h5>Προώθηση της επαγγελματικής σας σελίδας ανάμεσα στα πρώτα αποτελέσματα των αναζητήσεων μας</h5>
                                </li>
                                <li>
                                    <IconSvg Icon = 'Check'/>
                                    <h5>Προώθηση της επαγγελματικής σας σελίδας με πληρωμένες στρατηγικές μάρκετινγκ σε γνωστές μηχανές αναζήτησης</h5>
                                </li>
                                <li>
                                    <IconSvg Icon = 'Check'/>
                                    <h5>Ανάδειξη στις αναζητήσεις εντός πλατφόρμας</h5>
                                </li>
                                <li>
                                    <IconSvg Icon = 'Check'/>
                                    <h5>Ανάδειξη στα οργανικά αποτελέσματα των σελίδων μας μέσα από γνωστές μηχανές αναζήτησης</h5>
                                </li>
                                <li>
                                    <IconSvg Icon = 'Check'/>
                                    <h5>Έως τρεις περιοχές δραστηριοποίησης ανά συνδρομή</h5>
                                </li>
                                <li>
                                    <IconSvg Icon = 'Check'/>
                                    <h5>Έως τρεις κλάδους υπηρεσιών δραστηριοποίησης ανά συνδρομή</h5>
                                </li>
                                <li>
                                    <IconSvg Icon = 'Check'/>
                                    <h5>Έως 456 οργανικές και πληρωμένες αιτήσεις εργασίας από υποψήφιους πελάτες πάνω στον κλάδο υπηρεσιών μέχρι το πέρας της συνδρομής</h5>
                                </li>
                            </ul>
                            <DigTraVouTable>
                                <p></p>
                                <p>% (Ποσοστό)</p>
                                <p>€ (Τιμή)</p>
                                <h4>Συνολικό κόστος</h4>
                                <h4>100%</h4>
                                <h4>€ 4000,00</h4>
                                <p>Κρατική ενίσχυση</p>
                                <p>90%</p>
                                <p>€ 3600,00</p>
                                <p>Ιδία συμμετοχή</p>
                                <p>10%</p>
                                <p>€ 400,00</p>
                            </DigTraVouTable>
                            <DigTraVouVat>
                                <h6>Οι τιμές δεν συμπεριλαμβάνουν ΦΠΑ</h6>
                            </DigTraVouVat>
                            <DigTraVouBtn onClick = {() => toggleIntrestedSlider(true)}>
                                <h4>Ενδιαφέρομαι</h4>
                            </DigTraVouBtn>
                        </DigTraVouCard>
                    </main>
                </DigTraVouSectionRev>
            </DigTraVouMain>
        </DigTraVou>
    </Page>
  )
}
