import { Form, PageSixty, StepsWrapper, device } from 'monica-alexandria';
import styled, { keyframes } from 'styled-components';

const colorchange = keyframes`
  0% { transform: translateX(0) rotate(0deg)}
  50% { transform: translateX(-50%) rotate(0deg)}
  100%{ transform: translateX(0) rotate(0deg)}
`;

export const Landing = styled.div``;

export const LandingHeader = styled.div`
    position: relative;
    width: 100%;
    padding: 0 4rem;
    height: 70vh;
    min-height: 65rem;
    display: grid;
    grid-template-columns: auto 1fr;

    @media ${device.lg}{
        grid-template-columns: 1fr;
        height: auto;
    }

    @media ${device.sm} {
        padding: 0 2rem;
    }
`;

export const LandingCoverWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

export const LandingCover = styled.div`
    position: absolute;
    top: 0;
    left: 0%;
    width: 200vw;
    height: 100%;
    animation: ${colorchange} 5s infinite ease-in-out;
    background: linear-gradient(to right, var(--mainDark) -10%, var(--focus), var(--focusLight), var(--mainDark) 110%);
`;

export const LandingCoverCurve = styled.img`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    @media ${device.xl} {
        height: 15rem;
        object-fit: cover;
    }
`;

export const LandingHero = styled.div`
    position: relative;
    padding-top: 10rem;

    h1 {
        font-size: 4rem;
        margin-bottom: 2rem;
        color: var(--white);
    }

    h3 {
        margin-bottom: 4rem;
        color: var(--white);

        span {
            white-space: nowrap;
            border-bottom: 2px solid var(--white);
            padding-bottom: 0.4rem;
        }
    }

    @media ${device.xl} {
        max-width: 80rem;
    }

    @media ${device.sm} {
        padding-top: 5rem;

        h1 {
            font-size: 3rem;
        }

        h3 {
            font-size: 1.8rem;

            span {
                white-space: pre-wrap;
                line-height: 1.5;
            }
        }
    }
`;

export const LandingImages = styled.div``;

export const LandingImagesAmmount = styled.div`
    position: absolute;
    top: 10rem;
    right: 4rem;
    border-radius: 2rem;
    overflow: hidden;
    box-shadow: ${(p) => p.theme.out};
    height: 45rem;

    img {
        height: 45rem;
    }

    @media ${device.lg} {
        display: none;
    }
`;

export const LandingImagesDemographs = styled.div`
    position: absolute;
    top: 25rem;
    right: 25rem;
    border-radius: 2rem;
    overflow: hidden;
    box-shadow: ${(p) => p.theme.out};
    height: 45rem;

    img {
        height: 45rem;
    }

    @media ${device.xl} {
        display: none;
    }
`;

export const LandingMain = styled.div`

@media ${device.lg}{
        padding-top: 10rem;
    }
`;

const LandingSection = styled.div`
    padding: 8rem 4rem;

    h2 {
        margin-bottom: 4rem;
    }

    @media ${device.sm} {
        padding: 6rem 2rem;
    }
`;

export const LandingWhy = styled(LandingSection)`
    padding-top: 0;
`;

export const LandingWhyDesc = styled.h5`
    margin-bottom: 6rem;
    max-width: 40%;

    @media ${device.lg} {
        max-width: 100%;
    }
`;

export const LandingWhyGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 4rem;

    @media ${device.xl} {
        grid-template-columns: 1fr 1fr;
    }

    @media ${device.sm} {
        grid-template-columns: 1fr;
    }
`;

export const LandingWhyBox = styled.div`
    box-shadow: ${(p) => p.theme.outFocus};
    padding: 4rem;
    border-radius: 2rem;

    h3 {
        color: var(--focus);
        margin-bottom: 2rem;
    }
`;

export const LandingHow = styled(LandingSection)`
    position: relative;
`;

export const LandingHowLeft = styled.div``;

export const LandingHowRight = styled.div`
    position: absolute;
    right: 0rem;
    top: 0rem;
    width: calc(100% - 60rem);
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    @media ${device.lg} {
        display: none;
    }
`;

export const LandingHowGridRightKpi = styled.div`
    img {
        position: absolute;
        top: 10rem;
        right: 20%;
        width: 50rem;
        border-radius: 2rem;
        box-shadow: ${(p) => p.theme.out};
    }
`;

export const LandingHowConnect = styled.div`
    margin-bottom: 8rem;
    h3 {
        margin-bottom: 2rem;
    }

    p {
        max-width: 50rem;
    }

    @media ${device.lg} {
        p {
            max-width: none;
        }
    }
`;

export const LandingHowCreate = styled.div`
    margin-bottom: 4rem;
    h3 {
        margin-bottom: 2rem;
    }
`;

export const LandingHowCreateDesc = styled.p`
    width: 50rem;

    @media ${device.lg} {
        width: 100%;
    }
`;

export const LandingHowCreateGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 2rem;
    position: relative;
    z-index: 2;
    margin-top: 8rem;
    margin-bottom: 8rem;

    @media ${device.xl} {
        grid-template-columns: 1fr 1fr;
    }

    @media ${device.sm} {
        grid-template-columns: 1fr;
    }
`;

export const LandingHowCreateSub = styled.div`
    padding: 2rem 4rem;
    box-shadow: ${(p) => p.theme.out};
    background-color: ${(p) => p.theme.background};
    border-radius: 2rem;

    h4 {
        margin-bottom: 2rem;
        color: var(--main);
    }
`;

export const LandingHowCreateCta = styled.div`
    background-color: var(--main);
    border-radius: 2rem;
    padding: 2rem 4rem;
    cursor: pointer;
    p,
    h3 {
        color: var(--white);
    }

    &:hover {
        background-color: var(--mainLight);
    }
`;

export const LandingKey = styled(LandingSection)``;

export const LandingKeyGrid = styled.div`
    margin-top: 4rem;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 4rem;

    @media ${device.md} {
        grid-template-columns: 1fr;
    }
`;

export const LandingKeyGridLeft = styled.div``;

export const LandingKeyKpis = styled.div`
    padding: 2rem 0;
    background: ${(p) => p.theme.low};
    max-width: 60rem;
    border-radius: 4rem;
    overflow: hidden;
    box-shadow: ${(p) => p.theme.outFocus};

    img {
        width: 100%;
    }

    @media ${device.lg} {
        max-width: 50rem;
    }

    @media ${device.sm} {
        max-width: none;
    }
`;

export const LandingKeyGridRight = styled.div`
    h3 {
        margin-top: 4rem;
    }

    p {
        margin-top: 2rem;
    }
`;


export const LandingFormWrapper = styled.div`
    position: relative;
    width: 60rem;
    align-self: flex-start;
    margin-top: 10rem;
    margin-left: 10rem;

    @media ${device.lg}{
        margin: 0;
        width: 100% !important;
    }
`


export const LandingContainer = styled(StepsWrapper)`
    
    h2{
        text-align: center;
        margin-bottom: 4rem;
    }
`

const success = keyframes`
  from {
    opacity: 0;
    transform: translateX(15rem);
  }

  to {
    opacity: 1;
    transform: translateX(0rem);
  }
`;

export const LandingForm = styled(LandingContainer).attrs({ as: 'form' })`
    h3{
        margin-bottom: 1rem;
    }
`
export const LandingSuccess = styled(LandingContainer)`
    animation: ${success} .3s linear 1;
`

export const LandingRight = styled.div`
    
    .Counter{
        margin-bottom: 4rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;

        @media ${device.lg} {
            flex-direction: column;
        }
    }
`
