// This page makes sign in mandatory.
// Any page that uses this page as as a parent in Router, is a protected page.
// That means that only logged-in users can access it.
// The decision whether the user should navigate to login is made here.
// GuardedRoute is informed (via shouldRedirect prop) and acts accordingly.

import { React, useEffect, useState, createContext } from 'react';
import { GuardedRoute } from './Guard';

////////////////////////////////////////////////////////////////////
// Informs GuardedRoute that user must log in before continuing  ///
////////////////////////////////////////////////////////////////////
export const Protector = ({children}) =>  {

    return (<GuardedRoute shouldRedirect={true}>{children}</GuardedRoute>)
    
}


