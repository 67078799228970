import { Card, StepsWrapper, device } from "monica-alexandria";
import styled, { keyframes } from "styled-components";

export const LandingInterest = styled.div`
     section {
        padding: 10rem 4rem;
    }

    h2 {
        margin-bottom: 5rem;
    }

    @media ${device.md} {
        section{
            padding: 5rem 2rem;
        }
    }
`


export const LandingInterestHeader = styled.div`
    padding: 2rem 4rem;

    @media ${device.md} {
        padding: 2rem;
    }
`

export const LandingInterestHeaderWrapper = styled.div`
  //margin: 2rem 4rem;
  background: ${p => p.theme.low};
  box-shadow: ${p => p.theme.outFocus};
  border-radius: var(--normalRadius);
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  /* display: flex;
  justify-content: center; */

  @media ${device.lg} {
    grid-template-columns: 1fr;
    }

  @media ${device.sm} {
    margin: 2rem 2rem;
    }
`

export const LandingInterestHeaderImg = styled.img`
  position: absolute;
  width: 50%;
  max-width: 60rem;
  right: 5%;
  top: 2rem;

  @media ${device.lg} {
    position: static;
    width: 100%;
    margin: 0 auto;
    transform: translateY(8rem);
    height:100%;
    }
    @media ${device.md} {
      width: 90%;
      max-width: 50rem;
      transform: translateY(1rem);
    }
`

export const LandingInterestHeaderHero = styled.div`
  max-width: 108rem;
  margin: 0 auto;
  padding: 10rem 4rem;

  h1{
    font-size: 5rem;
    line-height: 1;
    margin-bottom: 2rem;
  //  text-align: center;
  }
  h5{
    //text-align: center;
  }

  button{
    margin-top: 2rem;
    width: 50%;
    justify-content: center;
    min-width: auto;
  }

  @media ${device.md} {
      h1{
        font-size: 5rem;
        line-height: 1;
        margin-bottom: 2rem;
      }
    }

    @media ${device.sm} {
      padding: 6rem 2rem;

      h1{
        font-size: 3rem;
        line-height: 1;
        margin-bottom: 2rem;
      }
    }
`

export const LandingInterestFormWrapper = styled.div`
    position: absolute;
    /* width: 50%;
    max-width: 60rem; */
    right: 10%;
    top: 0rem;

    width: 60rem;
    align-self: flex-start;
    margin-top: 10rem;
    margin-left: 10rem;

  @media ${device.xl} {
    position: static;
    width: 100%;
    margin: 0 auto;
    transform: translateY(8rem);
    height:100%;
     right: 0;
    }

    @media ${device.lg} {
    position: static;
    width: 100%;
    margin: 0 ;
    transform: translateY(0rem);
    height:100%; 
   
    
    }
    @media ${device.md} {
      width: 100%;
      max-width: 80rem;
      transform: translateY(1rem);
    }


   
`

export const LandingInterestContainer = styled(StepsWrapper)`
    background: ${(props) => props.theme.high};
    h2{
        text-align: center;
        margin-bottom: 4rem;
    }
`
export const LandingInterestForm = styled(LandingInterestContainer).attrs({ as: 'form' })`
    h3{
        margin-bottom: 1rem;
    }

    .LandingForm{
        margin-bottom: 1rem;
    }
`

const success = keyframes`
  from {
    opacity: 0;
    transform: translateX(15rem);
  }

  to {
    opacity: 1;
    transform: translateX(0rem);
  }
`;


export const LandingInterestSuccess = styled(LandingInterestContainer)`
    animation: ${success} .3s linear 1;
`

export const LandingInterestQuestions = styled.section`
   display: grid;
   grid-template-columns: 1fr 1fr;
   grid-gap: 8rem;


    @media ${device.md} {
        grid-template-columns: 1fr ;
        grid-gap: 0rem;
    }
`
export const LandingInterestQuestionsText = styled.div`
    text-align: center;
    padding: 16rem 4rem;

    h2{
        font-size: 5rem;
    }

    @media ${device.md} {
        padding: 8rem 2rem;
    }
`


export const LandingInterestQuestionsImgs = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-right: 2rem;
    align-items: flex-end;
    max-width: 100%;
    overflow: hidden;
   
    
    img{
        border-radius: var(--normalRadius);
        transform: translate(-2rem ,0);
        box-shadow: ${p => p.theme.inFocus};
        padding: 2px;

        &:first-of-type{
            transform: translate(2rem ,0);
            box-shadow: ${p => p.theme.outFocus};
            padding: 0;
        }
    }

    @media ${device.md} {
        align-items: flex-start;
        margin-right: 0;
        padding: 1rem;
        transform: translate(2rem, 0);

        img{
            transform: translate(0 , 0) !important;
        }
    }
`

export const LandingInterestAdSpend = styled.section`
    padding: 8rem 4rem;

    @media ${device.md} {
        padding: 8rem 2rem;
    }
`

export const LandingInterestAdSpendGrid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  justify-content: space-between;

  grid-gap: 20rem;

  @media ${device.md} {
    grid-template-columns: 1fr !important;
    grid-gap: 6rem;
  }


  @media ${device.lg} {
    grid-template-columns: 2fr 1fr;
    grid-gap: 2rem;
  }
`
export const LandingInterestAdSpendImg = styled.div`

 img{
        max-width: 40rem;
        margin: 0 auto;
        display: block;
    }


`

export const LandingInterestAdSpendText = styled.div`
  padding-right: 15rem ;
  h1{
    font-size: 5rem;
    line-height: 1;

  }
  border-radius: 20px;
  transition: 700ms;

h1{
  padding-bottom: 2rem;
}
  
@media ${device.md} {
  padding-right: 0rem ;
  }

  
`
export const LandingInterestHelpAction = styled.div`
    padding-top: 3rem;

`


export const LandingInterestPricing = styled.section`
    padding-top: 0rem !important;
`

export const LandingInterestPricingGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr ;
    grid-gap: 4rem;

 

    @media ${device.md} {
        grid-template-columns: 1fr;
    }
`;

export const LandingInterestPricingBox = styled(Card)`
    border: 0;
    box-shadow: none;
    padding: 4rem;
    background: ${p => p.action 
    ? `linear-gradient(to bottom right, var(--focus) -20%, var(--gloom), var(--greyDark))` 
    : `linear-gradient(to bottom right, var(--main) -20%, var(--gloom), var(--greyDark))`};


    h3 {
        text-align: center;
        color: var(--white);
        margin-bottom: 2rem;
    }
    p{
        color: var(--white);
        text-align: center;
    }

    .Sep {
        /* height: 2px;
        background: var(--white);
        width: 100%; */
    }

    .Price {
        display: flex;
        align-items: baseline;
        justify-content: center;
        position: relative;
    }

    .Price p {
        color: var(--white);
    }

    .Main {
        font-size: 7rem;
        font-weight: 900;
        font-family: 'fontBold';
    }

    .Top {
        height: 12rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .Discount {
        position: relative;

        p {
            color: var(--grey);
            text-align: center;
            font-size: 2rem;
        }

        .Cross {
            position: absolute;
            top: 50%;
            left: 50%;
            height: 2px;
            width: 7rem;
            background: var(--grey);
            transform: translate(-50%, -50%) rotate(5deg);
        }
    }

    ul {
        margin-top: 3rem;
    }

    @media ${device.lg} {
        grid-column: ${p => p.action ? 'span 2' : 'span 1'};
    }

    @media ${device.md} {
        grid-column: span 1;
    }
`;

export const LandingInterestQa = styled.section`
    padding: 8rem 4rem;

    @media ${device.md} {
        padding: 8rem 2rem;
    }
    background: ${(p) => p.theme.low};
    h2 {
        text-align: center;
    }
`;

export const LandingInterestQaGrid = styled.div`
    padding: 0 10rem;
    max-width: 100rem;
    margin: 0 auto;

    @media ${device.md} {
        padding: 0 2rem;
    }
`;

export const LandingInterestQaBox = styled(Card)`
    padding: 2rem;
    position: relative;
    overflow: unset;
    background: ${(p) => p.theme.background};
    margin-top: 2rem;
`;

export const LandingInterestQaQ = styled(Card)`
    padding: 2rem 4rem;
    background: linear-gradient(to bottom right, var(--mainDark), var(--main));
    transform: translate(-4rem, -4rem) rotate(-1deg);

    h3 {
        color: var(--white);
    }
`;

export const LandingInterestQaA = styled(Card)`
    padding: 2rem 4rem;
    background: linear-gradient(to bottom right, var(--mainDark), var(--focus));
    transform: translate(4rem, 0) rotate(1deg);

    p {
        color: var(--white);
        margin-top: 2rem;
    }

    h4 {
        color: var(--white);
        margin-bottom: 1rem;
    }

    h6 {
        color: var(--white);
        font-size: 1.8rem;
        font-family: 'fontLight';
    }
`;

export const LandingInterestHow = styled.section`

    button{
        margin: 4rem auto 0 auto;
    }
`

export const LandingInterestHowGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 4rem;
    padding: 8rem 4rem;

   

    @media ${device.xl} {
        grid-template-columns: 1fr;
    }

    @media ${device.md} {
        padding: 8rem 2rem;
    }
`

export const LandingInterestHowCard = styled(Card)`
    /* display: flex;
    gap: 2rem; */
    padding: 2rem 4rem;

    h3{
        color: var(--focus);
    }

    aside{
        h4{
            font-size: 15rem;
            color: var(--focus);
        }
    }

    main{
        margin-top: 3.5rem;
    }
`

export const LandingInterestHowCardLast = styled(LandingInterestHowCard)`

    .LandingInterestHowCardSplit{
        /* display: flex;
        gap: 2rem; */
    }
`

export const LandingInterestHowAction = styled.div`
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
`


export const LandingInterestWhyGrid = styled.div`
    padding: 1rem 4rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr ;
    grid-gap: 4rem;

    @media ${device.xl} {
        grid-template-columns: 1fr 1fr 1fr;
    }

    @media ${device.md} {
        grid-template-columns: 1fr;
    }
`;

export const LandingInterestWhyBox = styled.div`
    box-shadow: ${(p) => p.theme.outFocus};
    padding: 4rem;
    border-radius: 2rem;

    h3 {
        color: var(--focus);
        margin-bottom: 2rem;
    }
`;


export const LandingTestimonials = styled.section`
    padding: 8rem 4rem;
`;



export const LandingTestimonialsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4rem;


    @media ${device.md} {
        grid-template-columns: 1fr;
    }
   

` 


export const LandingTestimonialsBox = styled.div`
    display: flex;
    flex-direction: column;
   
    padding: 4rem;
    border-radius: var(--normalRadius);
    box-shadow: ${p => p.theme.out};
    
`

 export const LandingTestimonialsBoxImg = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    padding-bottom: 1.5rem;


    img{
        width: 6rem;
        height: 6rem;
        border-radius: 50%;
        object-fit: cover;
    }

`
