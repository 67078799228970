import { device } from "monica-alexandria";
import styled from "styled-components";

export const DigTraVou = styled.div`
    padding: 2rem 4rem;
`

export const DigTraVouHeader = styled.header`
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    img{
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
`

export const DigTraVouHero = styled.div`
    position: relative;

    h1{
        color: var(--white);
    }
`

export const DigTraVouMain = styled.div`
    
    section{
        padding: 10rem 0;
    }
`

export const DigTraVouSection = styled.section`
    display: grid;
    grid-template-columns: 40% 1fr;
    align-items: center;
    grid-gap: 4rem;

    main{
    
        h2{
            color: var(--main);

            span{
                color: var(--focus);
                font-size: 5rem;
            }
        }

        p{
            margin: 4rem 0;
        }
    }

    aside{

        img{
            display: block;
            width: 100%;
            max-width: 70rem;
            margin: 0 auto;
        }
    }

    @media ${device.md} {
        grid-template-columns: 1fr;
    }
`

export const DigTraVouCard = styled.div`
    background: linear-gradient(to bottom right, var(--main) -50%,  var(--focus) );
    padding: 4rem;
    border-radius: var(--normalRadius);

    h4{
        
    }

    ul{

        li{
            display: flex;
            align-items: center;
            gap: 2rem;
            margin-bottom: 1rem;

            svg{
                fill: var(--white);
                width: 2rem;
            }

            h5{
                color: var(--white);
            }
        }
    }
`

export const DigTraVouPrice = styled.div`
    text-align: center;
    border-bottom: 2px solid var(--white);
    padding-bottom: 4rem;
    margin-bottom: 4rem;

    h4{
        color: var(--white);
        font-size: 3rem;
    }
    
    .Per{
        font-size: 1.4rem;
    }
`

export const DigTraVouSectionRev = styled(DigTraVouSection)`
    grid-template-columns: 1fr 40%;

    @media ${device.md} {
        grid-template-columns: 1fr;

        aside{
            grid-row-start: 2;
            grid-row-end: 3;
        }
    }
`

export const DigTraVouTable = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    border: 1px solid var(--white);
    margin-top: 4rem;

    h4,
    p{
        padding: .5rem 1rem;
        color: var(--white);
        border: 1px solid var(--white);
        margin: 0 !important;
    }
`

export const DigTraVouVat = styled.div`
    margin-top: 2rem;
    text-align: center;

    h6{
        color: var(--white);
    }
`

export const DigTraVouBtn = styled.div`
    border: 2px solid var(--white);
    text-align: center;
    padding: 1rem 2rem;
    border-radius: 1rem;
    margin-top: 4rem;

    h4{
        color: var(--white) !important;
    }
`