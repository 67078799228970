import { CardsFull, Grey_Link, On_Click_Card, PageFull, PageTitle } from 'monica-alexandria'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import q from 'query-string';

const LandingPage = styled(PageFull)``

export default function LANDING_PAGE(props) {

    const {t} = useTranslation()
    const navigate = useNavigate();
    const {lang} = useParams();
    const parsed = q.parse(window?.location.search);
    let source = parsed?.source;

    const navigate_to_landing = () => {
      if (source == 'Questad') navigate(`/${lang}/questad-interest`)
      if (source == 'AdSnap') navigate(`/${lang}/adsnap-free-trial`)
      if (source == 'Early-Access') navigate(`/${lang}/v3-early-access`)
      if (source == 'Digital-Transformation') navigate(`/${lang}/ellada/vouchers/psifiakos-metasximatismos`)
    }

    const navigate_to_forms = () => {
      navigate(`/${lang}/akihiko/fahed/page/forms?source=${source || ''}`)
    }

  return (
    <LandingPage>
        <PageTitle>
          <Grey_Link iconLeft="Back" noPadding onClick = {()=> navigate(-1)} />
          <h1>{source ? source: 'Please go back and select a'}</h1>
        </PageTitle>
        <CardsFull>
            <On_Click_Card title={t("Visit page")} onClick = {() => navigate_to_landing()}/>
            <On_Click_Card title={t("View forms")} onClick = {() => navigate_to_forms()}/>
        </CardsFull>
    </LandingPage>
  )
}
