import React, { useContext, useEffect, useState } from 'react';
import {
    ThemeContext,
    HamContext,
    LangContext,
    MenuContext,
    Nav_,
    Grey_Link,
    Main_Plain,
    logo,
} from 'monica-alexandria';
import { config } from '../../config/index';
import { AuthContext } from '../../services/authentication/Guard';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import i18n from '../../localization/i18next';
import { checkPermissions } from '../../helpers/checkPermissions';
import store from '../../redux/store';
import { get_user_images } from '../../services/api/Users';
import { update_options_cookie } from '../../helpers/initMenuOptions';
import axios from 'axios';

const clients = require('../../config/index').config;
const authClient = process.env.REACT_APP_AUTH_CLIENT;
const client = process.env.REACT_APP_CLIENT;
// const paymentsclient = process.env.REACT_APP_PAYMENTS_CLIENT;

const minioUrl = clients['minio'];

const Navbar_ = () => {
    const { t } = useTranslation();
    const [textCta, setTextCta] = useState('');
    const [hamState, setHamState] = useState(false);
    const { setActiveMenu } = useContext(MenuContext);
    const { setLanguage } = useContext(LangContext);
    const { theme, setTheme } = useContext(ThemeContext);
    const [app, major, minor] = process.env.REACT_APP_VERSION.split('.').map(Number);

    const lang = i18n.language ? i18n.language : 'en';
    let navigate = useNavigate();
    const userId = store.getState()?.user?.user?.sub;

    const changeLanguage = (lang) => {
        let path = window.location.pathname.split('/');
        path[1] = lang;
        let newPath = path.join('/');
        i18n.changeLanguage(lang);
        navigate(newPath);
        setLanguage(lang);
        setActiveMenu('Main');
    };

    const auth = useContext(AuthContext);

    const [image, setImage] = useState(false);


    const permission = checkPermissions('Knight', auth.user.roles);

    const renderImage = () => {
        const userId = store.getState()?.user?.user?.sub;
        if ( !userId ) return;
        
        get_user_images(userId)
            .then((res) => {
                if (res?.data?.data?.images?.avatar != 'set') {
                    setImage(
                        minioUrl +
                            '/doitforme/morning/app/boiler/DefaultAvatar.jpg' +
                            '?v=' +
                            Math.random().toString(36).substring(2, 22)
                    );
                } else {
                    const imageVersion = Math.random()
                        .toString(36)
                        .substring(2, 22);
                    setImage(
                        minioUrl +
                            '/erisedev/' +
                            userId +
                            '/avatar.png' +
                            '?v=' +
                            imageVersion
                    );
                }
            })
            .catch((err) => {});
    };

    const getTextCta = () => {
        if (!auth.authenticated) {
            setTextCta('Get started');
        } else if (!permission) {
            setTextCta('New report');
        } else if (permission) {
            setTextCta('Admin');
        }
    };

    const navigateCta = () => {
        if (!auth.authenticated) {
            navigate(auth?.login());
        } else if (!permission) {
            navigate(`${lang}/connect-to-facebook`);
        } else if (permission) {
            window.location.assign(
                `${clients[client]}/${lang}/akihiko/${client}`
            );
        }
    };
    //admin -> akihiko
    //auth -> erised
    const logoCLick = () => {
        if (!auth.authenticated) {
            auth?.login();
        } else {
            window.location.assign(`${clients[authClient]}/${lang}`);
        }
    };

    const settingsClick = () => {
        window.location.assign(`${clients[authClient]}/${lang}/settings`);
    };

    const avatarClick = () => {
        window.location.assign(`${clients[authClient]}/${lang}`);
    };

    const logout = () => {
        auth?.logout();
    };


    useEffect(() => {
        store.dispatch({ type: 'THEME_UPDATE', payload: theme });
        if (theme != store.getState().user?.user?.preferences?.theme)
            update_options_cookie(userId);
    }, [theme]);



    useEffect(() => {
        getTextCta();
        renderImage();
    },[])
    
    return (
        <HamContext.Provider value={{ hamState, setHamState }}>
            <Nav_
                minorV = {minor}                
                majorV = {major}
                onClick_Logo={logoCLick}
                firstname_Account={auth?.user?.firstname}
                imgAlt_Account="Text"
                img_Account={image}
                onCLick_Logout={() => logout()}
                onClick_Account={avatarClick}
                onClick_Login={() => auth?.login(authClient)}
                onClick_Settings_Main={settingsClick}  
                role={permission ? 'Admin' :auth?.authenticated ? 'Protected' : 'Unprotected'}
                onClick_Admin_Main = {()=> navigate(`/${lang}/akihiko/fahed`)}
                text_Admin_Main = {t('Admin')}
                onClick_Subscriptions_Main =  {()=>window.location.assign(`${clients.griphook}/${lang}`)}
                text_Subscriptions_Main = {t('Subscriptions')}
                text_Login={t('Join')}
                text_Logout={t('Logout')}
                text_Settings_Main={t('Settings')}
                text_Theme_Main={t('Theme')}
                text_Title_Language={t('Language')}
                text_Title_Main={t('Menu')}
                text_Title_Region={t('Region')}
                onClick_English_Language={() => changeLanguage('en')}
                onClick_German_Language={() => changeLanguage('de')}
                onClick_Greek_Language={() => changeLanguage('el')}
            />
        </HamContext.Provider>
    );
};

export default Navbar_;
