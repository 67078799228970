import axios from "axios"
import { config } from "../../config"
import store from "../../redux/store"


const get_settings = (id) =>{
    return new Promise((resolve,reject) =>{
        axios.get(`${config.ERISED_BACKEND_URL}/users/${id}/getSettings`)
        .then(res => resolve(res))
        .catch((err) => {
            resolve(false);
        })
    })
}

const get_user_images = (userId) => {

    return new Promise((resolve, reject) => {

        axios.get(`${config.ERISED_BACKEND_URL}/users/getImages?userId=${userId}`)
        .then(res => {
            resolve(res)
        })
        .catch(() => {
            resolve(false);
        })
    })
}

const save_preferences = (userId) => {
    return new Promise((resolve, reject) => {
        

        const theme = store.getState().theme;
        const language = store.getState().selectedLanguage;
        const region = store.getState().country;

        axios.post(`${config.ERISED_BACKEND_URL}/users/savePreferences`, {
            data: {
                userId: userId,
                theme: theme,
                language: language,
                region: region
            }
        })
        .then(res => {
            resolve(res)
        })
        .catch(() => {
            resolve(false);
        })
    })
}


export {get_settings, get_user_images, save_preferences};
