import { device, H2, Main_Plain, P } from 'monica-alexandria';
import React from 'react'
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const LandingPricing = styled.section`
`

const LandingPricingGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4rem;

    p{
       max-width: 50rem;
    }

    @media ${device.lg} {
      grid-template-columns: 1fr;
    }
    
`;

const LandingPricingGridTitle = styled.div`
  .MainPlain{
      margin-top: 2rem;
    }
`;

const LandingPricingGridImg = styled.div`
    img{
        width: 90%;
        display: block;
        margin: 0 auto;
    }
`;


export const Dailyprofit_Interest_Pricing = (props) => {
    const {t} = useTranslation();
  return (
    <LandingPricing>
        <LandingPricingGrid>
       
            <LandingPricingGridTitle>
                <H2>{t('How are ads paid for on Questad?')} </H2>
                <P>{t("Questad uses a simple, transparent commission model with a flat 13% rate, regardless of your advertising budget. Payments are automatically processed every 30 days, covering the total prepaid amount, which includes your advertising budget, the 13% commission, and VAT. You'll receive a detailed invoice for each billing cycle, ensuring full visibility of costs.")}</P>
                <Main_Plain noPadding iconRight='Next' text={t("Start now")} size='large' onClick={props.onStart}/>

            </LandingPricingGridTitle>
            <LandingPricingGridImg>
                <img src='https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/pricing/PricingCommission.png'/>
            </LandingPricingGridImg>
           
        </LandingPricingGrid>
    </LandingPricing>
  )
}
