import React from 'react'
import { AlertContext, Builder_, Cta_, Footer_, H2, H3, H5, Input_, Main_, P, device } from 'monica-alexandria'
import styled from 'styled-components'
import Tracked_Dailyprofit_Interest_Header from './components/Tracked_Dailyprofit_Interest_Header'
import { LandingInterest, LandingInterestAdSpend, LandingInterestAdSpendGrid, LandingInterestAdSpendImg, LandingInterestAdSpendText, LandingInterestForm, LandingInterestFormWrapper, LandingInterestHeader, LandingInterestHeaderHero, LandingInterestHeaderWrapper, LandingInterestHelpAction, LandingInterestHow, LandingInterestHowCard, LandingInterestHowCardLast, LandingInterestHowGrid, LandingInterestPricing, LandingInterestPricingBox, LandingInterestPricingGrid, LandingInterestQa, LandingInterestQaA, LandingInterestQaBox, LandingInterestQaGrid, LandingInterestQaQ, LandingInterestQuestions, LandingInterestQuestionsImgs, LandingInterestQuestionsText, LandingInterestSuccess, LandingInterestWhyBox, LandingInterestWhyGrid, LandingTestimonials, LandingTestimonialsBox, LandingTestimonialsWrapper } from './TRACKED_DAILYPROFIT_INTEREST.css'
import { useTranslation } from 'react-i18next'
import store from '../../redux/store';
import { LandingTestimonialsBoxImg } from '../dailyprofitInterestForm/DAILYPROFIT_INTEREST.css';

import { useNavigate, useParams } from 'react-router-dom';
import { Dailyprofit_Interest_Business } from '../../components/home/Dailyprofit_Interest_Business';
import { Dailyprofit_Interest_Ads } from '../../components/home/Dailyprofit_Interest_Ads';
import { Dailyprofit_Interest_Dashboard } from '../../components/home/Dailyprofit_Interest_Dashboard';
import { Dailyprofit_Interest_Reporting } from '../../components/home/Dailyprofit_Interest_Reporting';
import { Dailyprofit_Interest_Euros } from '../../components/home/Dailyprofit_Interest_Euros';
import { Dailyprofit_Interest_Success } from '../../components/home/Dailyprofit_Interest_Success';
import { Dailyprofit_Interest_Qa } from '../../components/home/Dailyprofit_Interest_Qa';
import { Dailyprofit_Interest_Pricing } from '../../components/home/Dailyprofit_Interest_Pricing'

const TrackedDailyprofitInterest = styled.div`

    section {
      padding: 8rem 4rem;
      
    }

    h2{
      margin-bottom: 4rem;
    }
    @media ${device.xl}{
        h2{
        font-size: 3rem;
        }
        h1{
            font-size: 4rem;
        }
    }


    @media ${device.md} {
        section{
            padding: 4rem 2rem;
        }
    
    }

  .TrackPatching{
    padding: 8rem 4rem;

    @media ${device.md} {
        padding: 8rem 2rem;
    }
  }
`

export default function TRACKED_DAILYPROFIT_INTEREST() {
    const clients = require('../../config/index').config
    const {t} = useTranslation()
    const {lang} = useParams();

    const handleStart = () =>  {
        // window.location.href=`${clients['dailyprofit']}/${lang}/questionnaire/alpha`
        window.scrollTo({
          top: 0,
          behavior: 'smooth' // Smooth scroll animation
        });
    }

  return (
    <TrackedDailyprofitInterest>
      <Tracked_Dailyprofit_Interest_Header userId={store.getState()?.user?.user?.sub} />
      <LandingInterestQuestionsText>
            <H2>{t('You answer questions, we run ads!')}</H2>
            <P>{t('Just answer a few easy questions about your business, and our algorithm takes over!  Crafting automated digital marketing strategies for optimal performance depending your answers. We create campaigns, we adjust campaigns in retargeting your audience based on landing page visits and social engagement, all automatically without the need for marketing expertise by your side.')}</P>
        </LandingInterestQuestionsText>  
      
      <Dailyprofit_Interest_Business onStart={handleStart} />
        <Dailyprofit_Interest_Ads onStart={handleStart} />
        <Dailyprofit_Interest_Dashboard onStart={handleStart}/>
        <Dailyprofit_Interest_Pricing  onStart={handleStart}/>
        <Dailyprofit_Interest_Reporting  onStart={handleStart}/>
        <Dailyprofit_Interest_Euros  onStart={handleStart} />
    
        <Dailyprofit_Interest_Success  onStart={handleStart}/>
        <Dailyprofit_Interest_Qa  onStart={handleStart} />
 
    <Footer_ />
    </TrackedDailyprofitInterest>
  )
}
