//         ,''',
//        .' ., .',                                  ../'''',
//        .'. %%, %.',                            .,/' .,%   :
//       .'.% %%%,`%%%'.    .....,,,,,,.....   .,%%% .,%%'. .'
//       : %%% %%%%%%',:%%>>%>' .,>>%>>%>%>>%%>,.   `%%%',% :                         ||```````````````````````````||
//       : %%%%%%%'.,>>>%'   .,%>%>%'.,>%>%' . `%>>>,. `%%%:'                         ||                           ||
//       ` %%%%'.,>>%'  .,%>>%>%' .,%>%>%' .>>%,. `%%>>,. `%                          ||      ΠΡΟΣΟΧΗ: ΝΕΥΡΙΑΖΕΙ   ||
//        `%'.,>>>%'.,%%%%%%%' .,%%>%%>%' >>%%>%>>%.`%% %% `,                         ||     ΑΝ ΑΚΟΥΜΠΗΣΕΤΕ ΤΟΝ    ||
//        ,`%% %%>>>%%%>>%%>%%>>%>>%>%%%  %%>%%>%%>>%>%%%' % %,                       ||     ΚΩΔΙΚΑ Ή ΤΟ ΦΑΙ ΤΟΥ   || 
//      ,%>%'.>>%>%'%>>%%>%%%%>%'                 `%>%>>%%.`%>>%.                     ||                           ||
//    ,%%>' .>%>%'.%>%>>%%%>>%' ,%%>>%%>%>>%>>%>%%,.`%%%>%%. `%>%.                    ===============================
//   ` ,%' .>%%%'.%>%>>%' .,%%%%%%%%'          `%%%%%%.`%%>%% .%%>
//    .%>% .%%>' :%>>%%'.,%%%%%%%%%'.%%%%%' `%%%%.`%%%%%.%%%%> %%>%.
//   ,%>%' >>%%  >%' `%%%%'     `%%%%%%%'.,>,. `%%%%'     `%%%>>%%>%
//  .%%>%' .%%>'  %>>%, %% oO ~ Oo %%%>>'.>>>>>>. `% oO ~ Oo'.%%%'%>%,
//   %>'%> .%>%>%  %%>%%%'  `OoooO'.%%>>'.>>>%>>%>>.`%`OoooO'.%%>% '%>%
//   %',%' %>%>%'  %>%>%>% .%,>,>,   `>'.>>%>%%>>>%>.`%,>,>' %%%%> .>%>,
//   ` %>% `%>>%%. `%%% %' >%%%%%%>,  ' >>%>>%%%>%>>> >>%%' ,%%>%'.%%>>%.
//   .%%'  %%%%>%.   `>%%. %>%%>>>%.>> >>>%>%%%%>%>>.>>>'.>%>%>' %>>%>%%
//   `.%%  `%>>%%>    %%>%  %>>>%%%>>'.>%>>>>%%%>>%>>.>',%>>%'  ,>%'>% '
//    %>'  %%%%%%'    `%%'  %%%%%> >' >>>>%>>%%>>%>>%> %%>%>' .%>%% .%%
//   %>%>, %>%%>>%%,  %>%>% `%%  %>>  >>>%>>>%%>>>>%>>  %%>>,%>%%'.%>%,
//    %>%>%%, `%>%%>%>%, %>%%> ,%>%>>>.>>`.,.  `"   ..'>.%. % %>%>%'.%>%%;
//    %'`%%>%  %%>%%  %>% %'.>%>>%>%%>>%::.  `,   /' ,%>>>%>. >%>%'.%>%'%'
//    ` .%>%'  >%%% %>%%'.>%>%;''.,>>%%>%%::.  ..'.,%>>%>%>,`%  %'.>%%' '
//    %>%>%% `%>  >%%'.%%>%>>%>%>%>>>%>%>>%,,::,%>>%%>%>>%>%% `>>%>'
//     %'`%%>%>>%  %>'.%>>%>%>>;'' ..,,%>%>%%/::%>%%>>%%,,.``% .%>%%
// `    `%>%>>%%' %>%%>>%>>%>%>%>%%>%/'       `%>%%>%>>%%% ' .%'
//       %'  `%>% `%>%%;'' .,>>%>%/',;;;;;,;;;;,`%>%>%,`%'   '
//        `    `  ` `%>%%%>%%>%%;/ @a;;;;;;;;;;;a@  >%>%%'                  //////////////////////////////////////////////
//                   `/////////';, `@a@@a@@a@@aa@',;`//'                    // Περιγραφή: ΡΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑ-
//                       `//////.;;,,............,,;;//'                    // ΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑ
//                         `////;;;;;;;;;;;;;;;;;/'                         // ΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑ 
//                            `/////////////////'                           // ΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑ
//                                                                          // ΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΡΡ
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////                 

import { ThemeContext, LangContext, MenuContext, Page, RegionContext, Theme, ThemeDark, ThemeLight, _Nav, AlertContext } from "monica-alexandria";
import React, {useEffect, useState} from "react";
import { Routes, Route, Navigate, useNavigate} from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { GuardedRoute } from "../services/authentication/Guard";
import EARLY_ACCESS_V3_BETA from "../pages/earlyAccessV3Beta/EARLY_ACCESS_V3_BETA";
import PANEL from "../admin/views/panel/PANEL";
import TRANSLATIONS from "../admin/views/translations/TRANSLATIONS";
import DIGITAL_TRANSFORMATION from "../pages/digitalTransformation/DIGITAL_TRANSFORMATION";
import { Protector } from "../services/authentication//Protector";
import GDPR from "../pages/legal/GDPR";
import TERMS from "../pages/legal/TERMS";
import Navbar_ from "../components/navbar/Navbar_";
import Landing from "../pages/Landing/Landing";
import LANDINGS from "../admin/views/landings/LANDINGS";
import LOIS_FREE_TRIAL from "../pages/loisEarlyAccess/LOIS_FREE_TRIAL";
import USERS from "../admin/views/users/USERS";
import { init, reconstructUrl } from "../helpers/initMenuOptions";
import { useSelector } from 'react-redux';
import DAILYPROFIT_INTEREST from "../pages/dailyprofitInterestForm/DAILYPROFIT_INTEREST";
import LANDING_PAGE from "../admin/views/landingPage/LANDING_PAGE";
import FORMS from "../admin/views/forms/FORMS";
import TRACKED_DAILYPROFIT_INTEREST from "../pages/trackedDailyprofitInterest/TRACKED_DAILYPROFIT_INTEREST";

export default function Fahed() {

    const user = useSelector((state) => state.user.user);

    const navigate = useNavigate();

    const [theme, setTheme] = useState();
    const [language, setLanguage] = useState();
    const [region, setRegion] = useState();
    const [alerts, setAlerts] = useState([]);

    useEffect(() => {
        if (reconstructUrl(language)) navigate(reconstructUrl(language));
    }, [language]);

    useEffect(() => {
        init(user, setTheme, setLanguage, setRegion);
    }, [user]);

    return theme && language && region ? (
        <LangContext.Provider value={{ language, setLanguage }}>
            <RegionContext.Provider value={{ region, setRegion }}>
                <ThemeContext.Provider value={{ theme, setTheme }}>
                    <AlertContext.Provider value={{ alerts, setAlerts }}>
                        <ThemeProvider theme={theme === 'dark' ? ThemeDark : ThemeLight}>
                            <Theme>
                                <Routes>
                                    <Route
                                        path="/"
                                        element={
                                            <Navigate
                                                to={{
                                                    pathname: `/${language}/`,
                                                }}
                                            />
                                        }
                                    />
                                    <Route
                                        path="/"
                                        element={
                                            <GuardedRoute>
                                                <Navbar_/>
                                                <Landing/>
                                            </GuardedRoute>
                                        }
                                    />
                                    <Route
                                        path="/:lang"
                                        element={
                                            <GuardedRoute>
                                                <Landing/>
                                            </GuardedRoute>
                                        }
                                    />
                                    <Route path="/:lang/v3-early-access" element={<EARLY_ACCESS_V3_BETA/>} />
                                    <Route path="/:lang/data-protection-policy" element={<GDPR/>} />
                                    <Route path="/:lang/terms-and-conditions" element={<TERMS/>} />
                                    <Route path="/:lang/ellada/vouchers/psifiakos-metasximatismos" element={<DIGITAL_TRANSFORMATION/>} />
                                    <Route path="/:lang/adsnap-free-trial" element={<LOIS_FREE_TRIAL/>} />
                                    <Route path="/:lang/questad-interest" element={<GuardedRoute><DAILYPROFIT_INTEREST /></GuardedRoute>} />
                                    <Route path="/:lang/questad-interest/:id" element={<GuardedRoute><TRACKED_DAILYPROFIT_INTEREST /></GuardedRoute>} />
                                    {/* ADMIN */}
                                    <Route path="/:lang/akihiko/fahed" element={<Protector><PANEL/></Protector>} />
                                    <Route path="/:lang/akihiko/fahed/landings" element={<Protector><LANDINGS /></Protector>} />
                                    <Route path="/:lang/akihiko/fahed/page" element={<Protector><LANDING_PAGE /></Protector>} />
                                    <Route path="/:lang/akihiko/fahed/translations" element={<Protector><TRANSLATIONS/></Protector>} />
                                    <Route path="/:lang/akihiko/fahed/users" element={<Protector><USERS/></Protector>} />
                                    <Route path="/:lang/akihiko/fahed/page/forms" element={<Protector><FORMS/></Protector>} />
                                </Routes>
                            </Theme>
                        </ThemeProvider>
                    </AlertContext.Provider>
                </ThemeContext.Provider>
            </RegionContext.Provider>
        </LangContext.Provider>
    ) : (
        <></>
    );

}