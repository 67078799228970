import { H2, H3, H5, IconSvg, P } from 'monica-alexandria'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'


const LandingQa = styled.section`

`

const LandingQaTitle = styled.div`
   max-width: 100rem;
    margin: 0 auto;
    padding: 0 1rem 4rem 1rem ;
`

const LandingQaBox = styled.div`
    border-radius: 2rem;
    box-shadow: ${p=>p.theme.out};
    overflow: hidden;
    max-width: 100rem;
    margin: 0 auto;
    margin-bottom: 2rem;
`

const LandingQaQ  = styled.div`
    background: ${p=>p.theme.mid};
    display: flex;
    gap: 2rem;
    align-items: flex-start;
    justify-content: space-between;
    padding: 2rem;
    border-bottom: 2px solid ${p=>p.theme.background};
    cursor: pointer;
    transition: background .5s;
    svg{
        width: 2.5rem;
        fill: ${p=>p.theme.high};
    }
    &:hover{
        background:${p=>p.theme.background};

    }

    h3{
        font-size: 5rem;
        line-height: .5;
        color: ${p=>p.theme.high};
    }
`


const LandingQaA = styled.div`
    background: ${p=>p.theme.low};
    padding: 2rem;
`

export const Dailyprofit_Interest_Qa = () => {
    const {t} = useTranslation();
    const [clicked, setClicked] = useState([])

    const qaData = [
      {
        question: (t("Do I pay a single fee each month?")),
        answer: (t("Yes, you just pay a single fee, in a 30-day billing cycle, for using our platform. There are no hidden charges."))
      },
      {
        question: (t("Does the charge on your card every 30 days include your subscription?")),
        answer: (t("Yes, the amount charged to your card includes the subscription fee for using our platform."))
      },
      {
        question: (t("Can I get a refund?")),
        answer: (t("You can get a refund within the first 14 days of your subscription, on your subscription fee and any unspent ad budget. However, funds already spent on Meta or Google Ads cannot be refunded."))
      },
      {
        question: (t("Is there support available?")),
        answer: (t("Yes, we offer support. Any queries you have will be turned into tickets using your registered email."))
      },
      {
        question: (t("Can I control my ads through Questad?")),
        answer: (t("Absolutely! You can edit the visuals and captions of your ads through Questad's web interface."))
      },
      {
        question: (t("Can I stop my subscription immediately?")),
        answer: (t("You can cancel your subscription renewal at any time. Questad will ask if you want your ads to keep running for the remaining period of your subscription. If you choose to stop your ads, you can only reactivate them during your subscription days. The ad budget will continue to be used for the active days."))
      },
      {
        question: (t("How does Questad help simplify ad management for small businesses?")),
        answer: (t("Questad automates and simplifies the ad management process, making it easy for small businesses to run and optimize campaigns without needing extensive advertising expertise."))
      }
    ];
      
      const toggleAnswer = (index) => {
        if (clicked.includes(index)) {
          // If the question is already open, remove it from the array (close it)
          setClicked(clicked.filter(i => i !== index));
        } else {
          // If the question is not open, add it to the array (open it)
          setClicked([...clicked, index]);
        }
      };
    return (
        <LandingQa>
        <LandingQaTitle>
          <H2>{t("Q&A")}</H2>
        </LandingQaTitle>
        {qaData.map((item, index) => (
          <LandingQaBox key={index}>
            <LandingQaQ>
              <H5>{t(item.question)}</H5>
                {/* <H3>{clicked.includes(index) ? "-" : "+"}</H3> */}
              {/* <IconSvg Icon={clicked.includes(index) ? 'Xicon' : 'Add'} /> */}
            </LandingQaQ>
              <LandingQaA>
                <P>{t(item.answer)}</P>
              </LandingQaA>
          </LandingQaBox>
        ))}
      </LandingQa>
  )
}
