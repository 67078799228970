import { AlertContext, Builder_, Cta_, Footer_, H2, H3, H5, Input_, Main_, P, device } from 'monica-alexandria'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Helmet } from 'react-helmet';
import { LandingInterest, LandingInterestAdSpend, LandingInterestAdSpendGrid, LandingInterestAdSpendImg, LandingInterestAdSpendText, LandingInterestForm, LandingInterestFormWrapper, LandingInterestHeader, LandingInterestHeaderHero, LandingInterestHeaderWrapper, LandingInterestHelpAction, LandingInterestHow, LandingInterestHowCard, LandingInterestHowCardLast, LandingInterestHowGrid, LandingInterestPricing, LandingInterestPricingBox, LandingInterestPricingGrid, LandingInterestQa, LandingInterestQaA, LandingInterestQaBox, LandingInterestQaGrid, LandingInterestQaQ, LandingInterestQuestions, LandingInterestQuestionsImgs, LandingInterestQuestionsText, LandingInterestSuccess, LandingInterestWhyBox, LandingInterestWhyGrid, LandingTestimonials, LandingTestimonialsBox, LandingTestimonialsBoxImg, LandingTestimonialsWrapper } from './DAILYPROFIT_INTEREST.css'
import Header_Dailyprofit from './components/Header_Dailyprofit'
import { AuthContext } from '../../services/authentication/Guard'
import store from '../../redux/store';
import { Dailyprofit_Interest_Business } from '../../components/home/Dailyprofit_Interest_Business'
import { Dailyprofit_Interest_Ads } from '../../components/home/Dailyprofit_Interest_Ads'
import { Dailyprofit_Interest_Reporting } from '../../components/home/Dailyprofit_Interest_Reporting'
import { Dailyprofit_Interest_Euros } from '../../components/home/Dailyprofit_Interest_Euros'
import { Dailyprofit_Interest_Success } from '../../components/home/Dailyprofit_Interest_Success'
import { Dailyprofit_Interest_Qa } from '../../components/home/Dailyprofit_Interest_Qa'
import { Dailyprofit_Interest_Dashboard } from '../../components/home/Dailyprofit_Interest_Dashboard'
import { Dailyprofit_Interest_Pricing } from '../../components/home/Dailyprofit_Interest_Pricing'


export default function DAILYPROFIT_INTEREST() {
  const {t} = useTranslation()
  const navigate = useNavigate();
  const {lang} = useParams();
  const auth = useContext(AuthContext);

  const clients = require('../../config/index').config

  const handleStart = () =>  {
    // window.location.href=`${clients['dailyprofit']}/${lang}/questionnaire/alpha`
    window.scrollTo({
        top: 0,
        behavior: 'smooth' // Smooth scroll animation
      });
}

  useEffect(() => {
    if (auth?.authenticated) {
        navigate(`/${lang}/questad-interest/${store.getState()?.user?.user?.sub}`);
    }
  },[auth?.authenticated])

  return (
    <LandingInterest>
        <Helmet>
        {/* Google Tag Manager script */}
        <script async src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_QUESTAD_GOOGLE_ANALYTICS}`}></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.REACT_APP_QUESTAD_GOOGLE_ANALYTICS}');
          `}
        </script>
        {/* Google Tag Manager noscript */}
        {/* <noscript>
          {`<iframe src="https://www.googletagmanager.com/ns.html?id=${process.env.REACT_APP_QUESTAD_GOOGLE_ANALYTICS}"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>`}
        </noscript> */}
      </Helmet>
    <LandingInterestHeader>
        <Header_Dailyprofit/>
    </LandingInterestHeader>
        <LandingInterestQuestionsText>
            <H2>{t('You answer questions, we run ads!')}</H2>
            <P>{t('Just answer a few easy questions about your business, and our algorithm takes over!  Crafting automated digital marketing strategies for optimal performance depending your answers. We create campaigns, we adjust campaigns in retargeting your audience based on landing page visits and social engagement, all automatically without the need for marketing expertise by your side.')}</P>
        </LandingInterestQuestionsText>  
        <Dailyprofit_Interest_Business onStart={handleStart} />
        <Dailyprofit_Interest_Ads onStart={handleStart} />
        <Dailyprofit_Interest_Dashboard onStart={handleStart}/>
        <Dailyprofit_Interest_Pricing onStart={handleStart}/>
        <Dailyprofit_Interest_Reporting  onStart={handleStart}/>
        <Dailyprofit_Interest_Euros  onStart={handleStart} />
        <Dailyprofit_Interest_Success onStart={handleStart}/>
        <Dailyprofit_Interest_Qa  onStart={handleStart} />
        <Footer_ />
    </LandingInterest>





  )
}
