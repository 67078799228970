import { Alert_, Builder_, Cta_, Input_} from 'monica-alexandria'
import React, { useEffect, useState } from 'react'
import { One, OneForm, OneHeader, OneHero, OneIllu, OneRight, OneSuccess, OneWrapper } from './EARLY_ACCESS_V3_BETA.css'
import Recaptcha_ from '../../components/recaptcha/Recaptcha_'
import { useTranslation } from 'react-i18next'
import {create_user} from '../../services/api/Requests'


export default function EARLY_ACCESS_V3_BETA() {

    const recaptchaRef = React.createRef();
    const [submited, isSumbited] = useState(false);
    const {t} = useTranslation();

    const [email,setEmail] = useState("");
    const [errorsEmail, setErrorsEmail] = useState([]);
    const [successesEmail, setSuccessesEmail] = useState([]);
    const [helpersEmail, setHelpersEmail] = useState([]);

    const [alertError, setAlertError] = useState(false);
    const [alertSuccess, setAlertSuccess] = useState(false)

    const [captchaValue, setCaptchaValue] = useState()

    const validateEmailFormat = e => {
        let helperemail = [];
        let successemail = [];
        const email =  e.target.value;
        setEmail(email);
        if (!/\S+@\S+\.\S+/.test(email)) {
          helperemail.push(t('Correct email format "email@domain.com"'))
        } else {
          successemail.push(t('Correct email format "email@domain.com"'))
        }
          setHelpersEmail(helperemail);
          setSuccessesEmail(successemail);
    };

      //check inputs on submit
      const checkInputs = e =>{
        let erroremptyinput = t('This field should not be empty')
        e.preventDefault();
        //correct for errors
        if (helpersEmail?.length == 0 && email?.length != 0){
        //let added =  create_user(email);
          setAlertSuccess(true);
          
          const source = 'AdSnap'
          //save to db
          create_user(email,source)
          .then(res => {
          })
          .then(err => {
          })

        }
        else{
          setAlertError(true);
          setTimeout(() => {
            setAlertError(false)
          }, 8000);
          setErrorsEmail(helpersEmail)
        }
  
      //check for empty inputs
        if(email?.length== 0){
          setErrorsEmail([erroremptyinput]);
        }
        
        //clean inputs if there is an error
        if (helpersEmail != 0 ){
          setEmail("");
        }
      }

        useEffect(() => {
          document.title = 'Doitforme | V3 early access';
        }, []);

  return (
    <One>
        {/* {alertError &&
            <Alert_
            messages={[
                t('Invalid inputs, please check your inputs and try again...')
            ]}
            />
        } */}
        <OneHeader>
            <img className='Header' src='https://genius1071.friktoriaservers.net/doitforme/morning/app/fahed/One/header.svg' />
            <img className='Logo' src='https://genius1071.friktoriaservers.net/doitforme/logo/logoOneLine_dark.svg' />
            <OneHero>
                <h1>{t('Run "no-brainer" ads on')}<br/> {t('Facebook, Instagram & Google')}<br/> <span className='Without'>{t('without')}</span> {t('marketing knowledge!')}</h1>
                <h5>{t('Doitforme 3.0 helps small businesses, solopreneurs')}<br/> {t('and self-employment to generate customers ready to buy')}<br/> {t('by automating social media ads.')}</h5>
            </OneHero>
        </OneHeader>
        <OneWrapper>
            <OneIllu>
                <img className='Illus' src='https://genius1071.friktoriaservers.net/doitforme/morning/app/fahed/One/illus.svg' />
            </OneIllu>
            <OneRight>
                {/* <OneContainer className='Counter'>
                    <OneTill>
                        <h3>{t('Stay tuned')}</h3>
                    </OneTill>
                    <Countdown_ 
                        unixEchoStamp = {1674652365}
                        remainingDays = {240}
                    />
                </OneContainer> */}
                {/* <OneMid>{t('Join our waiting list and become one of the first to have early access and discover the features of our beta platform with unique privileges!')}</OneMid> */}
                {!alertSuccess ?
                    <OneForm 
                        onSubmit={(e) => {
                            e.preventDefault(); 
                            recaptchaRef.current.execute(); 

                        }}
                    >
                        <Recaptcha_ 
                            ref = {recaptchaRef}
                            captchaValue = {(val) => setCaptchaValue(val)}
                        />
                        <h2>{t('Request VIP access!')}</h2>
                        <Builder_ 
                            noPads
                            title= {t('Join our waiting list and become one of the first to have early access and discover the features of our beta platform with unique privileges!')}
                        >
                        <Input_ 
                            placeholder = {t('Email address')}
                            onChange = {validateEmailFormat}
                            helpers = {helpersEmail}
                            successes = {successesEmail}
                            errors = {errorsEmail}
                            value = {email}
                            required
                        />
                        </Builder_>
                        <Cta_ 
                            type = 'submit'
                            text = {t('Get early access')}
                            onClick = {checkInputs}
                        />
                    </OneForm>
                    :
                    <OneSuccess>    
                        <h3>{t('You successfully submitted your email to us! thank you.')}</h3>
                    </OneSuccess>
                    }   
            </OneRight>
        </OneWrapper>
        <img className='Element' src='https://genius1071.friktoriaservers.net/doitforme/morning/app/fahed/One/element.svg' />
    </One>
  )
}
