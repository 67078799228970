import { Sidebar_, Sidebar_Box } from 'monica-alexandria'
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

export default function Toolbar_() {

  const {lang} = useParams();
  const {t} = useTranslation();
  const navigate = useNavigate();

  return (
    <Sidebar_>
         <Sidebar_Box 
            text= {t('Home')}
            icon = 'Toolbar'
            onClick= {() => navigate(`/${lang}/akihiko/fahed/`)}
        />
        <Sidebar_Box 
            text= {t('Translations')}
            icon = 'Flag'
            onClick= {() => navigate(`/${lang}/akihiko/fahed/translations`)}
        />
        <Sidebar_Box 
            text= {t('Users')}
            icon = 'User'
            onClick= {() => navigate(`/${lang}/akihiko/fahed/users`)}
        />
         <Sidebar_Box 
            text= {t('Landings')}
            icon = 'Moon'
            onClick= {() => navigate(`/${lang}/akihiko/fahed/landings`)}
        />
    </Sidebar_>
  )
}