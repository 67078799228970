import React from 'react'
import styled from 'styled-components';
import {
    Builder_,
    Checkbox_,
    Dates_,
    Dropdown_,
    Main_,
    Slider_,
    countries,
} from 'monica-alexandria';

export default function User_Filters(props) {
    const UserFilters = styled.div``;
  return (
    <UserFilters>   
    <Slider_
        title="Filters" 
        text="Apply"
        onClose = {() => props.toggleFilters(false)}
        onClick = {() => props.toggleFilters(false)}
    >
    <Builder_ title="Sort by">
    <Dropdown_>
        {/* <select value={sortOption} onChange={(e) => setSortOption(e.target.value)}>
            <option value="UseridDesc">Userid (Desc)</option>
            <option value="UseridAsc">Userid (Asc)</option>
            <option value="NameAsc">Name (Asc)</option>
            <option value="NameDesc">Name (Desc)</option>
        </select> */}
    </Dropdown_>
    </Builder_>
    <Builder_ title="Country">
        <Dropdown_>
            {/* <select>
                {countries.map((item, i) => {
                    return <option key={i}>{item.country}</option>;
                })}
            </select> */}
        </Dropdown_>
    </Builder_>
    {/* <Builder_ title="Show only">
        <Checkbox_ text="Non subscribers" />
        <Checkbox_ text="Active subscribers" />
        <Checkbox_ text="All time subscribers" />
        <Checkbox_ text="Adsnap" />
        <Checkbox_ text="Questad" />
        <Checkbox_ text="Mini Website" />
        <div></div>
    </Builder_> */}
    {/* <Main_
        onClick={sortUsersHandler}
        text = 'Sort'
    /> */}
    <Builder_ title="Show users that registered on specific date range">
    </Builder_>
    </Slider_>
</UserFilters>
  )
}
