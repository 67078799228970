import { Builder_, Card, CardsFull, Dropdown_, Filters_, Google_Search, Grey_Link, IconSvg, Input_, Main_Plain, Notes_, PageFull, PageTitle, Red_, Section_Title, Slider_, TBody, TD, TH, THead, TR, TRH, Table, TableBullet, TableCard, TableCardActions, TableDesktop, TableMobile, Textarea_, device } from 'monica-alexandria'
import { React, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { edit_user, get_users } from '../../../services/api/Requests'
import q from 'query-string';
import { useNavigate } from 'react-router-dom'

const Forms = styled(PageFull)``

export default function FORMS(props) {

    const { t } = useTranslation()

    const [searchResults, setSearchResults] = useState([])
    const [users, setUsers] = useState([])
    const [displayed, setDisplayed] = useState([])
    const [slider, setSlider] = useState({
        active: '',
        status: false
    })
    const navigate = useNavigate();

    const [note, setNote] = useState('');

    const parsed = q.parse(window?.location.search);
    let source = parsed?.source;

    const date_options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    const updateUser = (open) => {

        // Updates user on the frontend
        let newUsers = JSON.parse(JSON.stringify(users))
        let newDisplayedUsers = JSON.parse(JSON.stringify(displayed))
        let userIndex = newUsers.findIndex(usr => usr?._id == slider?._id);
        let displayedUserIndex = newDisplayedUsers.findIndex(usr => usr?._id == slider?._id);

        // Updates user on the backend
        edit_user(slider, note)
        .then(res => {
            console.log(res)

            fetchUsers(source)

            if (note) {
                setSlider({...slider, open: open, notes: res?.data?.data?.user_note})
            }
            else {
                setSlider({...slider, open: open})
            }
        })
        .catch(err => {})  

        if (userIndex !== -1) {
            newUsers[userIndex].status = slider?.status;
            newUsers[userIndex].notes = slider?.notes;
            newDisplayedUsers[displayedUserIndex].status = slider?.status;
            newDisplayedUsers[displayedUserIndex].notes = slider?.notes;
        }
        setUsers(newUsers);
        setDisplayed(newDisplayedUsers);
        
        setNote('');
    }

    const fetchUsers = (source) => {
        get_users(source)
            .then(res => {
                console.log(res)
                if (res?.data?.data?.users?.length) {
                    setUsers(res?.data?.data?.users)
                    setDisplayed(res?.data?.data?.users)
                }
                else {
                    setUsers([])
                    setDisplayed([])
                }
            })
            .catch(err => {})
    }


    useEffect(() => {
        fetchUsers(source);
    }, [])

    useEffect(() => {
        if (searchResults?.data?.length) {
            setDisplayed(searchResults?.data)
        } else {
            setDisplayed(users)
        }
    }, [searchResults?.data])

    return (
        <Forms>
            {slider.open ?
                <Slider_ title={slider.active} onClick={() => {
                    updateUser(false);
                }}>
                    <Builder_ title={t("Set status")}>
                        <Dropdown_>
                            <select onChange={(e) => setSlider({ ...slider, status: e?.target?.value })}>
                                <option>{slider?.status ? slider?.status : 'No action'}</option>
                                <option>{t("Called")}</option>
                                <option>{t("Answered")}</option>
                                <option>{t("Declined")}</option>
                            </select>
                        </Dropdown_>
                    </Builder_>
                    <Notes_
                        input={
                            <Builder_ title={t("Notes")}>
                                <Textarea_ value={note}
                                    onChange={(e) => {
                                        setNote(e.target.value)  
                                    }}
                                    placeholder={t("Enter your notes here")} ></Textarea_>
                                <Main_Plain iconLeft={t("Add")} text={t("Add note")} onClick={() => {updateUser(true)}} />
                            </Builder_>
                        }
                        notes={slider?.notes}
                        user='adminId'
                        text='note'
                        date='createdAt'
                    />
                    {/* <Section_Title text={t("Advanced")} />
                <Red_ text={t("Delete form")} iconLeft={t("Delete")}/> */}
                </Slider_>
                :
                null
            }
            <PageTitle>
                <Grey_Link iconLeft="Back" noPadding onClick={() => navigate(-1)} />
                <h1>{source ? source : 'All'} {t("interest forms")}</h1>
            </PageTitle>
            <Filters_
                search={
                    <Google_Search
                        placeholder="Search users"
                        file={users}
                        fileKeys={['email', 'name', 'surname']}
                        defaultValue={''}
                        sanitize={true}
                        setResults={(data) => setSearchResults(data)}
                        minMatchCharacters={1}
                        maxMatchCharacters={50}
                        disabled={false}
                        isCaseSensitive={false}
                        maxAcceptedScore={0.6}
                    />
                }
            />
            <TableDesktop>
                <Table>
                    <THead>
                        <TRH>
                            <TH></TH>
                            <TH>{t("Status")}</TH>
                            <TH>{t("Email address")}</TH>
                            <TH>{t("First name")}</TH>
                            <TH>{t("Last name")}</TH>
                            <TH>{t("Phone number")}</TH>
                            <TH>{t("Monthly budget")}</TH>
                            <TH>{t("Submitted")}</TH>
                        </TRH>
                    </THead>
                    <TBody>
                        {displayed?.map((user, i) =>
                            <TR key={i}>
                                <TD>
                                    <Main_Plain noPadding iconLeft="Edit" onClick={() => setSlider({ ...slider, ...user, status: user?.status, notes: user?.notes, open: true, active: user?.surname })} />
                                </TD>
                                <TD>
                                    {user?.status === 'Called' ?
                                        <TableBullet status="var(--main)"></TableBullet>
                                        : user?.status === 'Declined' ?
                                            <TableBullet status="var(--error)"></TableBullet>
                                            : user?.status === 'Answered' ?
                                                <TableBullet status="var(--success)"></TableBullet>
                                                :
                                                null
                                    }
                                </TD>
                                <TD>{user?.email || 'No email address provived'}</TD>
                                <TD>{user?.name || '-'}</TD>
                                <TD>{user?.surname || '-'}</TD>
                                <TD>{user?.tel || '-'}</TD>
                                <TD>{user?.budget ? '€ ' + user?.budget : '-'}</TD>
                                <TD>{new Date(user?.createdAt).toLocaleDateString('el-GR', date_options)}</TD>
                            </TR>
                        )}
                    </TBody>
                </Table>
            </TableDesktop>
            <TableMobile>
                <CardsFull>
                    {displayed?.map((user, i) =>
                        <TableCard key={i}>
                            <TableCardActions>
                                <div>
                                    {user?.status === 'Called' ?
                                        <TableBullet status="var(--main)"></TableBullet>
                                        : user?.status === 'Declined' ?
                                            <TableBullet status="var(--error)"></TableBullet>
                                            : user?.status === 'Answered' ?
                                                <TableBullet status="var(--success)"></TableBullet>
                                                :
                                                null
                                    }
                                </div>
                                <Main_Plain noPadding iconLeft="Edit" onClick={() => setSlider({ ...slider, ...user, status: user?.status, notes: user?.notes, open: true, active: user?.surname })} />
                            </TableCardActions>
                            <table>
                                <TBody>
                                    <TR>
                                        <TD>
                                            <IconSvg Icon="Email" />
                                        </TD>
                                        <TD>{user?.email || 'No email address provived'}</TD>
                                    </TR>
                                    <TR>
                                        <TD>
                                            <IconSvg Icon="User" />
                                        </TD>
                                        <TD>{user?.name || '-'} {user?.surname || '-'}</TD>
                                    </TR>
                                    <TR>
                                        <TD>
                                            <IconSvg Icon="Phone" />
                                        </TD>
                                        <TD>{user?.tel || '-'}</TD>
                                    </TR>
                                    <TR>
                                        <TD>
                                            <IconSvg Icon="Budget" />
                                        </TD>
                                        <TD>{user?.budget ? '€ ' + user?.budget : '-'}</TD>
                                    </TR>
                                    <TR>
                                        <TD>
                                            <IconSvg Icon="Calendar" />
                                        </TD>
                                        <TD>{new Date(user?.createdAt).toLocaleDateString('el-GR', date_options)}</TD>
                                    </TR>
                                </TBody>
                            </table>
                        </TableCard>
                    )}
                </CardsFull>
            </TableMobile>
        </Forms>
    )
}
