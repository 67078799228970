import { Single_ } from "monica-alexandria";
import { useEffect } from "react";
import { useTranslation } from 'react-i18next'
import { useNavigate } from "react-router-dom";
import { checkPermissions } from "../../helpers/checkPermissions";
import i18n from "../../localization/i18next";

export default function Landing() {

    const {t} = useTranslation();
    const navigate = useNavigate();
    const lang = i18n.language;


    // if we are admins, we go straight to the admin panel
    // otherwise, we stay here
    useEffect(() => {
        if (checkPermissions('Baron')) navigate(`/${lang}/akihiko/fahed`);
    }, [])
    

    return(
        <Single_
        backgroundImage="https://genius1071.friktoriaservers.net/doitforme/morning/app/boiler/PageNotFound.svg"
        iconGrey="Rocket"
        iconMain="Rocket"
        onClickGrey="-"
        onClickMain="-"
        subtitle={t("Page Not Found")}
        text={t("Oops! It seems the page you're looking for has taken a vacation. It's probably sipping piña coladas on a beach somewhere. In the meantime, feel free to explore other parts of our website. Who knows, you might discover hidden treasures along the way!")}
        />
    )
}