import { CardsTools, MenuContext, On_Click_Card, Page, PageFull, PageTitle, PageTools } from 'monica-alexandria'
import React, { useState } from 'react'
import Toolbar_ from '../../partials/navigation/Toolbar_'
import Navbar_ from '../../../components/navbar/Navbar_'
import { GuardedRoute } from '../../../services/authentication/Guard'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

export default function PANEL() {

  const {lang} = useParams();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [activeMenu, setActiveMenu] = useState('Main');

  return (

    <Page>
      <MenuContext.Provider value={{ activeMenu, setActiveMenu }}>
        <GuardedRoute>
            <Navbar_ />              
        </GuardedRoute>
      </MenuContext.Provider>
      <PageFull>
          <PageTitle>
              <h1>{t("Launchpad's admin panel")}</h1>
          </PageTitle>
          <CardsTools>
            <On_Click_Card 
              icon = 'Folder'
              subtitle = {t('Landings')}
              onClick= {() => navigate(`/${lang}/akihiko/fahed/landings`)}
            />
            <On_Click_Card 
              icon = 'Flag'
              subtitle = {t('Translations')}
              onClick= {() => navigate(`/${lang}/akihiko/fahed/translations`)}
            />
          </CardsTools>
      </PageFull>
    </Page>
  )
}
