import { resolveTo } from "@remix-run/router"
import axios from "axios"
import { config } from "../../config"

const create_user = (email, source, captchaToken, formInput, tag_source) => {


    return new Promise((resolve, reject) => {

        axios.post(`${config.FAHED_BACKEND_URL}/users/createUser`,{
            email,
            source,
            captchaToken,
            ...formInput,
            tag_source
        })
            .then(res => {
                resolve(res)
            })
            .catch(() => {
                resolve(false);
        })
    })
}


const get_users = (source) => {

    return new Promise((resolve, reject) => {

        axios.get(`${config.FAHED_BACKEND_URL}/users/getUsers?source=${source || ''}`)
            .then(res => {
                resolve(res)
            })
            .catch(() => {
                resolve(false);
        })
    })
}

const edit_user = (userData, note) => {

    return new Promise((resolve, reject) => {

        axios.post(`${config.FAHED_BACKEND_URL}/users/editUser`,{userData, note})
            .then(res => {
                resolve(res)
            })
            .catch(() => {
                resolve(false);
        })
    })
}

export  {
    create_user,
    get_users,
    edit_user
}
