import { CardsTools, device, Dropdown_, Elastic_Search, Google_Search, Grey_Link, MenuContext, On_Click_Card, Page, PageTitle, PageTools, Section_Filters_Search, Section_Search } from 'monica-alexandria'
import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import Navbar_ from '../../../components/navbar/Navbar_'
import { GuardedRoute } from '../../../services/authentication/Guard'
import Toolbar_ from '../../partials/navigation/Toolbar_'
import { get_users } from '../../../services/api/Requests'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import User_Filters from '../../partials/users/User_Filters'


const DateDiv = styled.div`
  text-align: center;
 // margin-top: .5rem;
`

const PageToolsUtilities = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 2rem;
  margin-bottom: 4rem;

  @media ${device.sm} {
    grid-template-columns: 1fr;
  }
`

const demoJson = [
  {CITY: 'Larisa'},
  {CITY: 'Volos'},
  {CITY: 'Karditsa'}
]



export default function USERS() {

  const {lang} = useParams();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [activeMenu, setActiveMenu] = useState('Main');

  const date_options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

  const [users, setUsers] = useState([])

  const [searchResults, setSearchResults] = useState([]);
  const [filters, toggleFilters] = useState(false);
  const [displayedUsers, setDisplayedUsers] = useState([]);

  const fetchUsers = () => {
    get_users()
    .then(res => {
      console.log(res)
      if (res?.data?.data?.users?.length) setUsers(res?.data?.data?.users)
      else setUsers([])
    })
    .catch(err=>{})
  }

  console.log('USERS', searchResults);
  const applySearchResults = () => {
      if (searchResults?.data?.length) setDisplayedUsers(searchResults?.data);
      else setDisplayedUsers(users);
  };

  useEffect(() => {
      if (users?.length && !searchResults?.data?.length)
          setDisplayedUsers(users);
  }, [users]);

  useEffect(() => {
      applySearchResults();
  }, [searchResults?.input]);

  useEffect(() => {
    fetchUsers()
  },[])


  return (
    <Page>
    <PageTools>
      <MenuContext.Provider value={{ activeMenu, setActiveMenu }}>
        <GuardedRoute>
            <Navbar_ />              
        </GuardedRoute>
      </MenuContext.Provider>
      <Toolbar_/>
      {filters && 
          <User_Filters
          //users={users} 
          toggleFilters={toggleFilters} 
         //sortHandle={sortHandle} 
          //setSortHandle= {setSortHandle}
         /> 
      }
        <PageTitle>
            <Grey_Link iconLeft="Back" noPadding onClick = {()=> navigate(-1)} />
            <h1>{t('Users')}</h1>
        </PageTitle>
        <Section_Filters_Search 
          inputTitle = "" 
          filters= 'Filters' 
          onFilters= {()=>toggleFilters(true)}
        >
          <Google_Search 
              placeholder={t('Type in an email or an app')}
              file={users}
              fileKeys={['email', 'source']}
              sanitize={false}
              setResults={(result) => setSearchResults(result)}
              value={'bob'}
              debounceTime={750}
              isCaseSensitive={false}
              maxAcceptedScore={0.6}
              minMatchCharacters={0}
           />
        </Section_Filters_Search>
        <PageToolsUtilities>
          {/* <Elastic_Search 
            placeholder = 'Search for an email'
            json_File = {demoJson}
            suggestionType = 'CITY'
          /> */}
          {/* <Dropdown_ >
          <select>
              <option selected>{t('Sort by')}</option>
              <option >{t('Date asceding')}</option>
              <option >{t('Date descending')}</option>
          </select>
          </Dropdown_> */}
        </PageToolsUtilities>
        <CardsTools>
          {displayedUsers?.map((user) => {
            return  <On_Click_Card 
                      title= {user?.source}
                      subtitle = {user?.email}
                    >
                    <DateDiv>
                      <h6>{new Date(user?.createdAt).toLocaleDateString('el-GR',date_options)}</h6>
                    </DateDiv>
                  </On_Click_Card>
          })}
        </CardsTools>
    </PageTools>
    </Page>
  )
}
