import React from 'react'
import axios from 'axios'
import styled from 'styled-components';
import ReCAPTCHA from 'react-google-recaptcha';

export const Recaptcha = styled.div`
    transform: translateX(35rem);
    width: 0rem;
    height: 0rem;
    visibility: hidden;
    overflow: hidden;
`

export default function Recaptcha_(props) {
    
    function onChange(value) {
        props.setCaptchaValue(value)
    }
  return (
    <Recaptcha>
        <ReCAPTCHA
            ref={props.ref}
            size="invisible"
            sitekey="6Le-lSYjAAAAABhEe-_MhEvCmmh6eLFRQ-nBXG7c"
            onChange={onChange}
            badge = 'bottomright'
        />
    </Recaptcha>
  )
}
