import { CardsFull, CardsTools, Grey_Link, MenuContext, On_Click_Card, Page, PageFull, PageSubtitle, PageTitle, PageTools, Single_ } from "monica-alexandria";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from "react-router-dom";
import i18n from "../../../localization/i18next";
import Toolbar_ from "../../partials/navigation/Toolbar_";
import { GuardedRoute } from "../../../services/authentication/Guard";
import Navbar_ from "../../../components/navbar/Navbar_";

export default function LANDINGS() {

  const {lang} = useParams();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [activeMenu, setActiveMenu] = useState('Main');
  

    return(
      <Page>
      <MenuContext.Provider value={{ activeMenu, setActiveMenu }}>
        <GuardedRoute>
            <Navbar_ />              
        </GuardedRoute>
      </MenuContext.Provider>
        <PageFull>
          <PageTitle>
            <Grey_Link iconLeft="Back" noPadding onClick = {()=> navigate(-1)} />
            <h1>{t("Landing pages")}</h1>
          </PageTitle>
          <CardsTools>
            <On_Click_Card
              img="https://genius1071.friktoriaservers.net/doitforme/morning/app/fahed/landings/v3-early-access.png"
              title="Early access v3 beta"
              onClick = {()=>navigate(`/${lang}/akihiko/fahed/page?source=Early-Access`)}
            />
            <On_Click_Card
              img="https://genius1071.friktoriaservers.net/doitforme/morning/app/fahed/landings/lois-free-trial.png"
              title="Lois free trial"
              onClick = {()=>navigate(`/${lang}/akihiko/fahed/page?source=AdSnap`)}
            />
            <On_Click_Card
              img="https://genius1071.friktoriaservers.net/doitforme/morning/app/fahed/landings/questad-interest.png"
              title="Questad"
              onClick = {()=>navigate(`/${lang}/akihiko/fahed/page?source=Questad`)}
            />
            <On_Click_Card
              img="https://genius1071.friktoriaservers.net/doitforme/morning/app/fahed/landings/psifiakos-metasximatismos.png"
              title="Ψηφιακός μετασχηματισμός"
              onClick ={()=>navigate(`/${lang}/akihiko/fahed/page?source=Digital-Transformation`)}
            />
          </CardsTools>
        </PageFull>
      </Page>
    )
}