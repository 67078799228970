import React, { useContext, useState } from 'react'
import { Landing, LandingCover, LandingCoverCurve, LandingCoverWrapper, LandingForm, LandingFormWrapper, LandingHeader, LandingHero, LandingHow, LandingHowConnect, LandingHowCreate, LandingHowCreateCta, LandingHowCreateDesc, LandingHowCreateGrid, LandingHowCreateSub, LandingHowLeft, LandingHowRight, LandingImages, LandingImagesAmmount, LandingImagesDemographs, LandingKey, LandingKeyGrid, LandingKeyGridLeft, LandingKeyGridRight, LandingKeyKpis, LandingMain, LandingSuccess, LandingWhy, LandingWhyBox, LandingWhyDesc, LandingWhyGrid } from './LOIS_FREE_TRIAL.css'
import { useTranslation } from 'react-i18next';
import Recaptcha_ from '../../components/recaptcha/Recaptcha_';
import { AlertContext, Alert_, Builder_, Cta_, Input_, Main_, Theme, ThemeContext } from 'monica-alexandria';
import { create_user } from '../../services/api/Requests';
import i18n from '../../localization/i18next';
import { Helmet } from 'react-helmet';

const clients = require('../../config/index').config;
const lois = process.env.REACT_APP_LOIS_CLIENT;

export default function LOIS_FREE_TRIAL() {
    const { t } = useTranslation();
    const { theme } = useContext(ThemeContext);
    const recaptchaRef = React.createRef();

    const lang = i18n.language ? i18n.language : 'en';
    const [email,setEmail] = useState("");
    const [errorsEmail, setErrorsEmail] = useState([]);
    const [successesEmail, setSuccessesEmail] = useState([]);
    const [helpersEmail, setHelpersEmail] = useState([]);

    const [alertError, setAlertError] = useState(false);
    const [alertSuccess, setAlertSuccess] = useState(false);

    const [captchaValue, setCaptchaValue] = useState()

    const { alerts, setAlerts } = useContext(AlertContext);

    // const themee  = useTheme();
   

   const alertMessage = (message, status) => {
        setAlerts([
            ...alerts,
            {
                message: {
                    text: message,
                    status: status,
                },
            },
        ]);
    };


    const validateEmailFormat = e => {
        let helperemail = [];
        let successemail = [];
        const email =  e.target.value;
        setEmail(email);
        if (!/\S+@\S+\.\S+/.test(email)) {
          helperemail.push(t('Correct email format "email@domain.com"'))
        } else {
          successemail.push(t('Correct email format "email@domain.com"'))
        }
          setHelpersEmail(helperemail);
          setSuccessesEmail(successemail);
    };

      //check inputs on submit
      const checkInputs = e =>{
        
        let erroremptyinput = t('This field should not be empty')
        e.preventDefault();
        //correct for errors
        if (helpersEmail?.length == 0 && email?.length != 0){
        //let added =  create_user(email);
          setAlertSuccess(true);
          const source = 'AdSnap'
          //save to db
          create_user(email, source)
          .then(res => {
          })
          .then(err => {
          })

        }
        else{

        //   setAlertError(true);
        //   setTimeout(() => {
        //     setAlertError(false)
        //   }, 8000);
            
          setErrorsEmail(helpersEmail)
        //   alertMessage(t('Invalid inputs, please check your inputs and try again...'), 'Error');
        //   alertMessage(t('Report deleted successfully...'), 'Success');
        }
  
      //check for empty inputs
        if(email?.length== 0){
          setErrorsEmail([erroremptyinput]);
        }
        
        //clean inputs if there is an error
        if (helpersEmail != 0 ){
          setEmail("");
        }
      }

  return (
        <Landing>
            <Helmet>
                <title>Fahed | Adsnap</title>
                <script>
                    {`(function(w,d,s,l,i){
                    w[l]=w[l]||[];
                    w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
                    var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
                    j.async=true;
                    j.src='https://www.googletagmanager.com/gtm.js?id=${process.env.REACT_APP_GOOGLE_TAG_MANAGER}'+dl;
                    f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','${process.env.REACT_APP_GOOGLE_TAG_MANAGER}');`}
                </script>
                <script async src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GOOGLE_ANALYTICS}`}></script>
                <script>
                    {`window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());

                    gtag('config', '${process.env.REACT_APP_GOOGLE_ANALYTICS}');
                    `}
                </script>
                {/* Google Tag Manager noscript */}
                <noscript>
                {`<iframe src="https://www.googletagmanager.com/ns.html?id=${process.env.REACT_APP_GOOGLE_TAG_MANAGER}"
                    height="0" width="0" style="display:none;visibility:hidden"></iframe>`}
                </noscript>
            </Helmet>
           
            {/* {alertError && */}
            {/* <Alert_ messages={alerts}/> */}
            <LandingHeader>
                <LandingCoverWrapper>
                    <LandingCover></LandingCover>
                    <LandingCoverCurve src={theme == 'dark' ? 'https://genius1071.friktoriaservers.net/doitforme/morning/app/lois/landing/dark/LandingHeaderCurve.svg' : 'https://genius1071.friktoriaservers.net/doitforme/morning/app/lois/landing/light/LandingHeaderCurve.svg'} />
                </LandingCoverWrapper>
                <LandingHero>
                    <h1>
                        {t('Facebook Ads Reporting.')}
                        <br />
                        {t(' Stunning visuals, fully customizable metrics')}
                    </h1>
                    <h3>
                        {t('Enjoy unlimited reports and streamline your ad performance analysis.')}
                        <br />
                        <span>{t('Experience the Adsnap difference today!')}</span>
                    </h3>
                    {/* <Main_ text={t("Get Started")} iconRight="Rocket" size="large" onClick={() => handleNavigation()} /> */}
                </LandingHero>
                <LandingFormWrapper>
                {!alertSuccess ?
                    <LandingForm 
                        onSubmit={(e) => {
                            e.preventDefault(); 
                            recaptchaRef.current.execute(); 

                        }}
                    >
                        <Recaptcha_ 
                            ref = {recaptchaRef}
                            captchaValue = {(val) => setCaptchaValue(val)}
                        />
                        <h3>{t('Claim your free trial!')}</h3>
                        <Builder_ 
                            noPads
                            title= {t('Enter your email address')}
                        >
                        <Input_ 
                            placeholder = {t('Email address')}
                            onChange = {validateEmailFormat}
                            helpers = {helpersEmail}
                            successes = {successesEmail}
                            errors = {errorsEmail}
                            value = {email}
                            required
                        />
                        </Builder_>
                        <Main_ 
                            type = 'submit'
                            text = {t('Get started')}
                            onClick = {checkInputs}
                            iconRight="Rocket"
                        />
                    </LandingForm>
                    :
                    <LandingSuccess>    
                        <h3>{t('You successfully submitted your email to us! thank you.')}</h3>
                    </LandingSuccess>
                    }   
                    {/* <LandingImagesDemographs>
                    <img src={theme == 'dark' ? "https://genius1071.friktoriaservers.net/doitforme/morning/app/lois/DemographsDark.png": "https://genius1071.friktoriaservers.net/doitforme/morning/app/lois/DemographsLight.png" }/>
                    </LandingImagesDemographs>
                    <LandingImagesAmmount>
                    <img src={theme == 'dark' ? "https://genius1071.friktoriaservers.net/doitforme/morning/app/lois/AmountDark.png" : "https://genius1071.friktoriaservers.net/doitforme/morning/app/lois/AmountLight.png" } />
                    </LandingImagesAmmount> */}
                {/* </LandingImages> */}
                </LandingFormWrapper>
            </LandingHeader>
            <LandingMain>
                <LandingWhy>
                    <h2>{t('Why AdSnap?')}</h2>
                    <LandingWhyDesc>
                        {t('Are you tired of spending hours manually creating reports for your Facebook ad campaigns?')}
                        <br />
                        {t('Adsnap is here to help. With our platform, you can generate customized, easy-to-understand reports in minutes.')}
                        <br />
                        {t('Adsnap seamlessly integrates with your Facebook business accounts, allowing you to focus on what matters most, optimizing your campaigns for maximum results!')}
                    </LandingWhyDesc>
                    <LandingWhyGrid>
                        <LandingWhyBox>
                            <h3>{t('Save Time')}</h3>
                            <p>{t("Automated report generation for your clients' Meta ad accounts in minutes.")}</p>
                        </LandingWhyBox>
                        <LandingWhyBox>
                            <h3>{t('Optimize campaigns')}</h3>
                            <p>{t('Gain valuable insights to your digital marketing strategies.')}</p>
                        </LandingWhyBox>
                        <LandingWhyBox>
                            <h3>{t('Simplify data')}</h3>
                            <p>{t('Easy-to-understand reports customized to your needs.')}</p>
                        </LandingWhyBox>
                        <LandingWhyBox>
                            <h3>{t('Easy setup')}</h3>
                            <p>{t('Seamless integration with your Facebook business accounts.')}</p>
                        </LandingWhyBox>
                    </LandingWhyGrid>
                </LandingWhy>
                <LandingHow>
                    <h2>{t('How it works')}</h2>
                    <LandingHowLeft>
                        <LandingHowConnect>
                            <h3>{t('Connect')}</h3>
                            <p>{t('Link your Facebook business account to Adsnap, enabling our platform to access the essential ad data for creating your personalized reports')}</p>
                        </LandingHowConnect>
                        <LandingHowCreate>
                            <h3>{t('Create')}</h3>
                            <LandingHowCreateDesc>{t("Effortlessly construct an all-encompassing report. Utilize Adsnap's user-friendly interface and powerful features to create a detailed, informative, and visually appealing report that captures the essence of your Facebook and Instagram ad campaigns, without breaking a sweat.")}</LandingHowCreateDesc>
                            <LandingHowCreateGrid>
                                <LandingHowCreateSub>
                                    <h4>{t('KPIs & custom fields')}</h4>
                                    <p>{t('Access valuable insights with intelligently retrieved Key Performance Indicators and tailor your report by including custom fields addressing your unique requirements.')}</p>
                                </LandingHowCreateSub>
                                <LandingHowCreateSub>
                                    <h4>{t('Demographics analysis')}</h4>
                                    <p>{t('Gain a deeper understanding of your target audience through detailed demographic information based on your key metrics.')}</p>
                                </LandingHowCreateSub>
                                <LandingHowCreateSub>
                                    <h4>{t('Facebook & Instagram pages')}</h4>
                                    <p>{t('Obtain a holistic picture of your social media presence by incorporating performance data from both your Facebook and Instagram pages.')}</p>
                                </LandingHowCreateSub>
                                <LandingHowCreateCta onClick={()=> window.location.assign(`${clients[lois]}/${lang}`)} >
                                    <h3>{t('Create one now')}</h3>
                                    <p>{t('Experience the ease and efficiency of Adsnap by trying it now, and see firsthand how it can transform your digital marketing campaigns')}</p>
                                </LandingHowCreateCta>
                            </LandingHowCreateGrid>
                        </LandingHowCreate>
                        <LandingHowConnect>
                            <h3>{t('Export and Share')}</h3>
                            <p>{t('Watch as Adsnap creates a stunning, easy-to-understand report for your selected KPIs. Export your report to a PDF for easy sharing with your team or clients.')}</p>
                        </LandingHowConnect>
                    </LandingHowLeft>
                    <LandingHowRight>
                        <img src="https://genius1071.friktoriaservers.net/doitforme/morning/app/lois/HowItWorksBg.svg" />
                    </LandingHowRight>
                </LandingHow>
                <LandingKey>
                    <h2>{t('Key Features')}</h2>
                    <h5>{t('Experience the power of automated report generation and gain valuable insights to optimize your digital marketing campaigns. Adsnap offers a range of features designed to streamline your Facebook ad reporting.')}</h5>
                    <LandingKeyGrid>
                        <LandingKeyGridLeft>
                            <LandingKeyKpis>
                                <img src={theme == 'dark' ? 'https://genius1071.friktoriaservers.net/doitforme/morning/app/lois/AddKpisDark.png' : 'https://genius1071.friktoriaservers.net/doitforme/morning/app/lois/AddKpisLight.png'} />
                            </LandingKeyKpis>
                        </LandingKeyGridLeft>
                        <LandingKeyGridRight>
                            <h3>{t('Automated report generation')}</h3>
                            <p>{t("Say goodbye to manual data entry and save time with Adsnap's automated report creation.")}</p>
                            <h3>{t('Customizable KPI metrics')}</h3>
                            <p>{t('Choose from a wide range of KPIs to create a report tailored to your business needs and goals.')}</p>
                            <h3>{t('Easy-to-understand reports')}</h3>
                            <p>{t('Visualize your data with beautiful, clear graphs and charts that make interpreting your ad performance a breeze.')}</p>
                            <h3>{t('Seamless integration')}</h3>
                            <p>{t('Adsnap easily connects to your Facebook business accounts for a hassle-free setup and user experience.')}</p>
                            <h3>{t('PDF export')}</h3>
                            <p>{t('Share your reports with your team or clients by exporting them as professional-looking PDFs.')}</p>
                        </LandingKeyGridRight>
                    </LandingKeyGrid>
                </LandingKey>
            </LandingMain>
            {/* <Foot_ /> */}
        </Landing> 
  )
}

