import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import Fahed from './router/Router';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './services/authentication/Keycloak'
import {jwtReqInterceptor, jwtResInterceptor} from './helpers/interceptor'
import { expireSession } from './services/authentication/SessionService';
import i18n from './localization/i18next';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import store from './redux/store';

let options = {onLoad: 'check-sso', checkLoginIframe: false}

const root = ReactDOM.createRoot(document.getElementById('root'));

const authentication = process.env.REACT_APP_AUTHENTICATION


jwtReqInterceptor();
jwtResInterceptor();

{authentication === 'ON' ?
  root.render(
    <ReactKeycloakProvider 
        onEvent={(evt) => {
          if (evt == 'onTokenExpired') {expireSession()}
          if (evt == 'onAuthRefreshError') {expireSession()}
          if (evt == 'onAuthError') {expireSession()}
        }} 
        initOptions={options} 
        authClient={keycloak} 
        LoadingComponent={<div></div>}
      >
      <Provider store={store}>
        <BrowserRouter>
          <I18nextProvider i18n={i18n}>
            <Fahed/>
          </I18nextProvider>
        </BrowserRouter>
      </Provider>
    </ReactKeycloakProvider>
  )

  :

  root.render(
    <Provider store={store}>
      <BrowserRouter>
        <I18nextProvider i18n={i18n}>
          <Fahed/>
        </I18nextProvider>
      </BrowserRouter>
    </Provider>
  )
}


