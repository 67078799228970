import { device, H3, P } from 'monica-alexandria'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const LandingDashboard = styled.section`

`

const LandingDashboardGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 4rem;

    @media ${device.lg} {
      grid-template-columns: 1fr ;
    }
`

const LandingDashboardLeft = styled.div``

const LandingDashboardBox = styled.div`
    width: 100%;
    border-radius: 2rem;
    box-shadow:${p=>p.theme.out};
    overflow: hidden;
`
const LandingDashboardContent = styled.div`
    padding: 2rem;
    background: ${p=>p.theme.mid};
`

const LandingDashboardImg = styled.div`
  width: 100%;
  background: ${p=>p.theme.mid};
    
  img{
    width: 100%;
    height: 50rem;
    object-fit: cover;
    object-position: top;
  }

  @media ${device.lg} {
    //padding: 1rem;
      img{
        height: auto;
      }
  }

  
`
const LandingDashboardList = styled.ul`
    li{
      h5{
        margin-top: 2rem;
        font-size: 1.6rem;
      }
    }
`

const LandingDashboardRight = styled.div`
`
export const Dailyprofit_Interest_Dashboard = () => {
  const {t} = useTranslation();
  return (
    <LandingDashboard>
      <LandingDashboardGrid>
        <LandingDashboardLeft>
          <LandingDashboardBox>
            <LandingDashboardContent>
              <H3>{t("Strategic adspend allocation")}</H3>
              <P>{t("Our app optimises social media campaigns by analysing your responses to questionnaires. Depending on your main business objective and your monthly budget, Questad tailors strategies to effectively reach your audience. Using advanced algorithms, it takes into account factors such as audience demographics and past performance to maximize your ad spend. Strategic allocation of ad spend.")}</P>
            </LandingDashboardContent>
            <LandingDashboardImg>
              <img src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/dashboard/Reccomend.png" alt=""/>
            </LandingDashboardImg>
          </LandingDashboardBox>
          
        </LandingDashboardLeft>
        <LandingDashboardRight>
          <LandingDashboardBox>
              <LandingDashboardImg>
                <img src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/dashboard/Full.png" alt=""/>
              </LandingDashboardImg>
              <LandingDashboardContent>
                <H3>{t("Stay always in control")}</H3>
                <P>{t("Monitor your ads in Real-Time, access your dashboard for instant analytics and performance tracking. What can you track?")}</P>
                <LandingDashboardList>
                  <li>
                      <h5>{t("Reach")}</h5>
                      <P>{t("Monitor how far your ads are going.")}</P>
                    </li>
                    <li>
                      <h5>{t("Clicks")}</h5>
                      <P>{t("See how many users are engaging with your ads.")}</P>
                    </li>
                    <li>
                      <h5>{t("Leads")}</h5>
                      <P>{t("Keep tabs on the leads generated from your campaigns.")}</P>
                    </li>
                    <li>
                      <h5>{t("Landing Page Views")}</h5>
                      <P>{t("Understand the performance of your landing pages.")}</P>
                    </li>
                </LandingDashboardList>
              </LandingDashboardContent>
            </LandingDashboardBox>
        </LandingDashboardRight>
      </LandingDashboardGrid>
    </LandingDashboard>
  )
}
