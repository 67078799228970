import { device, PageSixty, StepsWrapper } from "monica-alexandria";
import styled, { keyframes } from "styled-components";

const success = keyframes`
  from {
    opacity: 0;
    transform: translateX(15rem);
  }

  to {
    opacity: 1;
    transform: translateX(0rem);
  }
`;

export const One = styled.div`
    position: relative;
    min-height: 100vh;

    .Element{
        position: absolute;
        bottom: 0;
        right: 0;
        width: 15rem;
    }
`

export const OneHeader = styled.header`
    position: relative;

    .Header{
        width: 100%;
        height: 60vh;
        min-height: 55rem;
        object-fit: cover;
    }

    .Logo{
        width: 25rem;
        position: absolute;
        top: 6rem;
        left: 50%;
        transform: translate(-50%, 0%);
    }
`

export const OneRight = styled.div`
    
    .Counter{
        margin-bottom: 4rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;

        @media ${device.lg} {
            flex-direction: column;
        }
    }
`

export const OneHero = styled.div`
    position: absolute;
    top: 15rem;
    left: 50%;
    transform: translate(-50% , 0);
    width: 100%;
    max-width: 85rem;
    margin: 0 auto;
    
    h1{
        color: var(--white);
        text-align: center;
        font-size: 5rem;
        font-family: 'fontMedium';
        //line-height: .9;
        letter-spacing: -1px;
    }

    h5{
        color: var(--white);
        text-align: center;
        width: 100%;
        margin: 0 auto;
        max-width: 75rem;
        margin-top: 2rem;
        font-size: 2rem;
        font-family: 'fontLight';
    }

    .Without{
        font-family: 'fontLight';
    }

    @media ${device.md} {
        
        h1{
            font-size: 2rem;
        }

        h5{
            font-size: 1.6rem;
            padding: 0 2rem;
        }
    }
`

export const OneWrapper = styled(PageSixty)`
    position: relative;
    z-index: 2;
    width: 100%;
    margin: 0 auto;
    transform: translateY(-10rem);
    align-items: flex-start;
    max-width: 1400px;

    .Illus{
        justify-self: flex-end;
        margin-right: 4rem;
    }

    @media ${device.md} {
        transform: translateY(-20rem);
    }
`

export const OneIllu = styled.div`

    img{
        max-height: 50rem;
    }

    @media ${device.md} {
        display: none;
    }
`

export const OneContainer = styled(StepsWrapper)`
    
    h2{
        text-align: center;
        margin-bottom: 4rem;
    }
`

export const OneForm = styled(OneContainer).attrs({ as: 'form' })`
    
`

export const OneTill = styled.div`
    padding: 2rem 1rem;
    border-right: 2px solid ${p => p.theme.mid};

    @media ${device.lg} {
        border-right: none;
    }
`

export const OneMid = styled.h5`
    padding: 0 2rem 4rem 2rem;
`

export const OneSuccess = styled(OneContainer)`
    animation: ${success} .3s linear 1;
`
