import { Builder_, CardsTools, Input_, MenuContext, On_Click_Card, PageTitle, PageTools, Slider_ } from 'monica-alexandria'
import React, { useEffect, useState } from 'react'
import { findMissing, getTranslations, updateTranslations } from '../../../services/api/Translations';
import Toolbar_ from '../../partials/navigation/Toolbar_';

import _ from 'lodash';
import { Keymaker_ } from '../../partials/keymaker/Keymaker_';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { GuardedRoute } from '../../../services/authentication/Guard';
import Navbar_ from '../../../components/navbar/Navbar_';

const protocol = process.env.REACT_APP_FAHED_AKIHIKO_TRANSLATIONS_PROTOCOl;
const host = process.env.REACT_APP_FAHED_AKIHIKO_TRANSLATIONS_HOST;
const path = process.env.REACT_APP_FAHED_AKIHIKO_TRANSLATIONS_PORT;

export default function TRANSLATIONS() {

  const {lang} = useParams();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [activeMenu, setActiveMenu] = useState('Main');

  useEffect(() =>{
    if(process.env.REACT_APP_SERVER === 'PRODUCTION') window.location.assign(`${protocol}${host}/${lang}${path}`)
  },[])

  return (
    <PageTools>
      <MenuContext.Provider value={{ activeMenu, setActiveMenu }}>
        <GuardedRoute>
            <Navbar_ />              
        </GuardedRoute>
      </MenuContext.Provider>
      <Toolbar_/>
      <PageTitle>
        <h1>{t("Available languages")}</h1>
      </PageTitle>

      <Keymaker_ />
      
    </PageTools>
  )
}
